import React, { useState } from 'react';

import { connect } from 'react-redux';

import { getAzureB2CChangePasswordLink } from '../../../../../../api/azureB2C';

import ChangePasswordForm from '../../ChangePasswordForm';
import { changePassword } from '../../../actions';

const PasswordSection = ({ userInfo, selectedLanguage, profilePage, changePassword }) => {
  const [isPswdChanged, setPswdChanged] = useState(false);

  const onPasswordSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    if (await changePassword(userInfo.id, values.oldPassword.trim(), values.newPassword.trim())) {
      setPswdChanged(true);

      resetForm();
      setSubmitting(false);

      setTimeout(() => {
        setPswdChanged(false);
      }, 2000);
    } else {
      setSubmitting(false);
    }
  };

  const changePasswordWithAzureB2C = async () => {
    const returnUri = window.location.origin + '/profile-settings';

    window.location.href = await getAzureB2CChangePasswordLink(returnUri, selectedLanguage);
  };

  return (
    <>
      <ChangePasswordForm
        isChanged={isPswdChanged}
        requestError={profilePage.passwordError}
        changePasswordWithAzureB2C={changePasswordWithAzureB2C}
        onSubmit={onPasswordSubmit}
      />
    </>
  );
};

const stateProps = ({ app: { userInfo }, profilePage, language: { selectedLanguage } }) => ({
  userInfo,
  profilePage,
  selectedLanguage,
});

const actionProps = {
  changePassword,
};

export default connect(stateProps, actionProps)(PasswordSection);
