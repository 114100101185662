import React from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';

import { Button, Spinner, Title } from 'jpi-cloud-web-ui-components';

import { Wizard, WizardSteps } from '../../../../layout/Wizard';
import Modal from '../../../../layout/Modal';

import SelectModeSettings from './SelectModeSettings';
import ModePropertyEditor from './ModePropertyEditor';
import SelectMode from './SelectMode';

import { eventEmitter, events } from '../../../../tracking/AppInsights';
import { setModes, getScheduleConfig, getModes } from '../../actions';
import { POPUP_STATES, POPUP_TITLES } from './constants';
import { weeklySchedulesType } from '../../types';
import { errorMessages } from './DeleteMode';

import '../../device-scheduling.scss';

class EditModesPopup extends React.Component {
  state = {
    loading: false,
    selectedMode: null,
    selectedModeSettings: [],
    title: POPUP_TITLES[POPUP_STATES.VIEW],
  };

  async componentDidMount() {
    const { userIsViewer, getScheduleConfig, getModes } = this.props;

    this.setState({ loading: true, title: POPUP_TITLES[userIsViewer ? POPUP_STATES.VIEW : POPUP_STATES.EDIT] });

    await getScheduleConfig(this.getDeviceId);
    await getModes(this.getDeviceId);

    this.setState({ loading: false });
  }

  get getDeviceId() {
    const { devices = [] } = this.props;
    const [mainDevice = {}] = devices;

    return mainDevice?.id;
  }

  saveModeHandler = async () => {
    const { modes, closePopup } = this.props;
    const { selectedMode, selectedModeSettings } = this.state;

    const settings = selectedModeSettings
      .filter(s => s.selected)
      .map(s => ({ settingId: s.settingId, value: s.value }));
    const modesToSave = modes.map(m => (m.modeId === selectedMode.modeId ? { ...m, settings } : m));

    await this.saveModes(modesToSave);

    closePopup();
  };

  reloadModes = async () => {
    this.setState({ loading: true });

    // FIXME: chunk by @jeetasher
    // Device does not send updated modes instantly, need to wait for some uncertain amount of time (see https://nibejpi.atlassian.net/browse/CLOUDTEAM-6647).
    // TODO: Remove once the issue is fixed on device side before release.
    await new Promise(resolve => setTimeout(() => resolve(), 3000));
    // by @jeetasher

    // FIXME: Keep delay just for testing purposes.
    await this.props.getModes(this.getDeviceId);
    this.setState({ loading: false });
  };

  saveModes = async (modesToSave, reload = false) => {
    const { intl, setModes } = this.props;

    this.setState({ loading: true });

    try {
      await setModes(this.getDeviceId, modesToSave);
      if (reload) await this.reloadModes();
    } catch (error) {
      NotificationManager.error(intl.formatMessage(errorMessages.unexpected));
      eventEmitter.emit(events.appInsights.logError, error);
    }

    this.setState({ loading: false });
  };

  getSelectedSettings = (settings, reset = false) => {
    const settingsToProcess =
      !reset && this.state.selectedModeSettings && this.state.selectedModeSettings.length
        ? this.state.selectedModeSettings
        : this.props.scheduleConfig.modeSettings;

    if (this.props.scheduleConfig.forceAllModeSettings) {
      return this.props.scheduleConfig.modeSettings
        .map(setting => ({ ...setting, selected: true }))
        .map(setting => {
          const ms = settings.find(ms => setting.settingId === ms.settingId);
          if (ms) return { ...setting, value: ms.value };
          return setting;
        });
    }

    return settingsToProcess.map(setting => {
      const ms = settings.find(ms => setting.settingId === ms.settingId);
      if (ms) return { ...setting, value: ms.value, selected: ms.selected === false ? false : true };
      return { ...setting, value: setting.defaultValue, selected: false };
    });
  };

  selectedModeChanged = mode => {
    const selectedSettings = this.getSelectedSettings(mode.settings);

    this.setState({
      selectedMode: mode,
      selectedModeSettings: selectedSettings,
    });
  };

  resetSelectedMode = () => {
    this.setState({
      selectedMode: null,
    });
  };

  modeSettingsChanged = settings => {
    const selectedSettings = this.getSelectedSettings(settings);

    this.setState({
      selectedModeSettings: selectedSettings,
    });
  };

  changeSettingsValue = (setting, value) => {
    const selectedModeSettings = this.state.selectedModeSettings.map(s =>
      s.settingId === setting.settingId ? { ...s, value } : s,
    );
    this.setState({ selectedModeSettings });
  };

  resetSettings = (goPrevious, reset) => {
    const settings = this.getSelectedSettings(this.state.selectedMode.settings, reset);

    this.setState({
      selectedModeSettings: settings,
    });

    goPrevious();
  };

  changeView = async (viewState, reload) => {
    if (reload) await this.reloadModes();

    this.setState({ title: POPUP_TITLES[viewState] });
  };

  render() {
    const { isModePopupOpen, modes, scheduleConfig, userIsViewer, weeklySchedules, closePopup } = this.props;
    const { selectedMode, selectedModeSettings, loading } = this.state;

    return (
      <Modal show={isModePopupOpen} backdrop="static">
        <div className="mode-popup">
          <Title titleTranslationId={this.state.title.id} defaultMessage={this.state.title.defaultMessage} />
          <Button className="button--cancel" type="button" onClick={closePopup}>
            <i className="fas fa-times" />
          </Button>
          {loading ? (
            <Spinner dark />
          ) : (
            <Wizard stepsCount={3}>
              {({ currentStep, goNext, goPrevious }) => (
                <WizardSteps currentStep={currentStep}>
                  <SelectMode
                    modes={modes}
                    isVacation={false}
                    deviceId={this.getDeviceId}
                    weeklySchedules={weeklySchedules}
                    selectedMode={userIsViewer ? null : selectedMode}
                    scheduleConfig={scheduleConfig}
                    userIsViewer={userIsViewer}
                    onViewStateChanged={this.changeView}
                    onSelectedChanged={this.selectedModeChanged}
                    resetSelectedMode={this.resetSelectedMode}
                    saveModes={this.saveModes}
                    goPrevious={closePopup}
                    goNext={goNext}
                  />
                  <SelectModeSettings
                    modeSettings={selectedModeSettings}
                    scheduleConfig={scheduleConfig}
                    onSelectedChanged={this.modeSettingsChanged}
                    goPrevious={() => this.resetSettings(goPrevious, true)}
                    goNext={goNext}
                  />
                  <ModePropertyEditor
                    modeSettings={selectedModeSettings.filter(item => item.selected)}
                    changeSettingsValue={this.changeSettingsValue}
                    goPrevious={() => this.resetSettings(goPrevious, false)}
                    goNext={this.saveModeHandler}
                  />
                </WizardSteps>
              )}
            </Wizard>
          )}
        </div>
      </Modal>
    );
  }
}

EditModesPopup.propTypes = {
  isModePopupOpen: PropTypes.bool,
  modes: PropTypes.array.isRequired,
  weeklySchedules: weeklySchedulesType,
  scheduleConfig: PropTypes.object,
  devices: PropTypes.array.isRequired,
  selectedModes: PropTypes.array,
  userIsViewer: PropTypes.bool,
  onModeSelected: PropTypes.func,
  closePopup: PropTypes.func.isRequired,
  getScheduleConfig: PropTypes.func.isRequired,
  getModes: PropTypes.func.isRequired,
  setModes: PropTypes.func.isRequired,
};

export default connect(
  ({ devices: { devices }, deviceScheduling: { modes, scheduleConfig, weeklySchedules } }) => ({
    modes,
    devices,
    scheduleConfig,
    weeklySchedules,
  }),
  {
    getScheduleConfig,
    getModes,
    setModes,
  },
)(EditModesPopup);
