import React from 'react';
import PropTypes from 'prop-types';

import {
  UIBoolean,
  UIDropdown,
  UIEvent,
  UIGroup,
  UIHeader,
  UIInfoCountdown,
  UIInfoParameter,
  UIInfoText,
  UILink,
  UINumerical,
  UIOptionGroup,
  UISlider,
  UITextBox,
  UITextSetting,
  UITime,
  UITimeSetting,
} from './DeviceMenuItems';

const DeviceMenuItem = ({
  menuItem,
  followLink,
  processEvent,
  changeSetting,
  isManageEnabled,
  isDemoUser,
  premiumFeatureType,
  accordionId,
}) => {
  switch (menuItem.type) {
    case 'uievent':
      return <UIEvent menuItem={menuItem} processEvent={processEvent} isDemoUser={isDemoUser} />;
    case 'uiinfotext':
      return <UIInfoText menuItem={menuItem} />;
    case 'uilongtext':
      return <UIInfoText menuItem={menuItem} />;
    case 'uitimesetting':
      return (
        <UITimeSetting
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
        />
      );
    case 'uiinfoparameter':
      return <UIInfoParameter menuItem={menuItem} isDemoUser={isDemoUser} />;
    case 'uioptiongroup':
      return (
        <UIOptionGroup
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uitextsetting':
      return (
        <UITextSetting
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
        />
      );
    case 'uiboolean':
      return (
        <UIBoolean
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uinumerical':
      return (
        <UINumerical
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uiheader':
      return <UIHeader menuItem={menuItem} />;
    case 'uilink':
      return <UILink menuItem={menuItem} followLink={followLink} />;
    case 'uiinfocountdown':
      return <UIInfoCountdown menuItem={menuItem} />;
    case 'uitime':
      return <UITime menuItem={menuItem} changeSetting={changeSetting} isDemoUser={isDemoUser} />;
    case 'uitextbox':
      return (
        <UITextBox
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uidropdown':
      return (
        <UIDropdown
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uislider':
      return (
        <UISlider
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uigroup':
      return (
        <UIGroup
          accordionId={accordionId}
          menuItem={menuItem}
          isManageEnabled={isManageEnabled}
          premiumFeatureType={premiumFeatureType}
          changeSetting={changeSetting}
          isDemoUser={isDemoUser}
        />
      );
    default:
      return null;
  }
};

DeviceMenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  followLink: PropTypes.func.isRequired,
  processEvent: PropTypes.func.isRequired,
  changeSetting: PropTypes.func.isRequired,
  isManageEnabled: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
  accordionId: PropTypes.number,
};

export default DeviceMenuItem;
