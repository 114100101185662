import { getFromLocalStorageByKey, removeFromLocalStorage, saveToLocalStorage } from './index';

const localStorageKeys = {
  MENU_LEVELS_HISTORY: 'MENU_LEVELS_HISTORY',
  MENU_ID: 'MENU_ID',
};

export const getMenuLevelsHistoryFromLocalStorage = () =>
  getFromLocalStorageByKey(localStorageKeys.MENU_LEVELS_HISTORY);
export const setMenuLevelsHistoryToLocalStorage = value =>
  saveToLocalStorage(localStorageKeys.MENU_LEVELS_HISTORY, value);
export const removeMenuLevelsHistoryFromLocalStorage = () =>
  removeFromLocalStorage(localStorageKeys.MENU_LEVELS_HISTORY);

export const getMenuIdFromLocalStorage = () => getFromLocalStorageByKey(localStorageKeys.MENU_ID);
export const setMenuIdToLocalStorage = value => saveToLocalStorage(localStorageKeys.MENU_ID, value);
export const removeMenuIdFromLocalStorage = () => removeFromLocalStorage(localStorageKeys.MENU_ID);
