import { Button } from 'jpi-cloud-web-ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DemoAccountVerifier } from '../../../../../../layout/DemoAccountVerifier';

const PremiumAvailable = ({ formattedValidUntil, hasRightsToBuy, addToCart, variants = [], disabled }) => {
  return (
    <div className="Product__isPremiumAvailable">
      <p className="Product__validTo">
        <FormattedMessage id="shop.page.validTo" defaultMessage="Valid until" />
        &#58; &nbsp;
        {formattedValidUntil}
      </p>
      <p className="Product__existing-subscription-paragraph">
        <FormattedMessage
          id="shop.product.subscriptionExisting"
          defaultMessage={
            'You have an active subscription for this product, you can renew it when less then 30 days remaining.'
          }
        />
      </p>
      {hasRightsToBuy &&
        variants.map((variant, index) => (
          <DemoAccountVerifier position="top" key={'productVariant__' + index}>
            <Button className="product__subscription-button" disabled={disabled} onClick={() => addToCart(variant, 1)}>
              <FormattedMessage id="shop.page.addToCart" defaultMessage="Add to cart" />
            </Button>
          </DemoAccountVerifier>
        ))}
    </div>
  );
};

PremiumAvailable.propTypes = {
  formattedValidUntil: PropTypes.string.isRequired,
  hasRightsToBuy: PropTypes.bool,
  addToCart: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  variants: PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default PremiumAvailable;
