import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getFirmwaresByBrand, getMyFirmwares, getActiveBrands } from './actions';
import { Spinner, Title } from 'jpi-cloud-web-ui-components';
import Dropdown from '../../inputs/Dropdown';

import FirmwareDownload from './components/FirmwareDownload';
import ChangeLogModal from './components/ChangeLogModal';
import _ from 'lodash';

import './update-device-firmware.scss';

class UpdateDeviceFirmware extends React.Component {
  static propTypes = {
    getFirmwaresByBrand: PropTypes.func.isRequired,
    getMyFirmwares: PropTypes.func.isRequired,
    getActiveBrands: PropTypes.func.isRequired,
    firmwares: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    userInfo: PropTypes.object,
    systems: PropTypes.arrayOf(PropTypes.object),
    brands: PropTypes.arrayOf(PropTypes.object),
    selectedSystemBrandId: PropTypes.string,
    intl: PropTypes.object,
  };

  state = {
    showModal: false,
    selectedItem: { value: '1', label: { id: 'devices.brand.mySystems', defaultMessage: 'My Systems' } },
    items: [{ value: '1', label: { id: 'devices.brand.mySystems', defaultMessage: 'My Systems' } }],
  };

  async loadFirmwares() {
    const { systems, brands } = this.props;
    let items = brands.map(b => ({
      value: b.id,
      label: b.name,
    }));

    if (systems && systems.length) {
      items.push({ value: '1', label: { id: 'devices.brand.mySystems', defaultMessage: 'My Systems' } });
    } else {
      items.push({ value: '0', label: { id: 'devices.brand.selectBrand', defaultMessage: 'Select a Brand' } });
    }

    items = items.filter(item => item.value !== 'MYUPLINK');
    items = _.orderBy(items, ['value'], ['asc']);
    this.setState({ items: items });
    this.setState({ selectedItem: items[0] });
    if (systems && systems.length) {
      await this.getMySystemsFirmwares();
    }
  }

  async componentDidMount() {
    await this.props.getActiveBrands();
    await this.loadFirmwares();
  }

  async componentDidUpdate(prevProps) {
    const { brands } = this.props;
    if (brands !== prevProps.brands) {
      if (this.props.brands && !this.props.loading) {
        await this.loadFirmwares();
      }
    }
  }

  async getMySystemsFirmwares() {
    const { systems } = this.props;
    if (systems && systems.length) {
      await this.props.getMyFirmwares();
    }
  }

  toggleModal = () => {
    this.setState({ ...this.state, showModal: !this.state.showModal });
  };

  render() {
    const { loading, firmwares, userInfo, loaded, intl } = this.props;
    const isDemo = userInfo && userInfo.isDemo;
    const sortedFirmwares = _.orderBy(firmwares, ['created'], ['desc']);
    const latestFirmwares = _.uniqBy(sortedFirmwares, 'firmwareTypeId');

    return (
      <div className="page-content">
        <div className="update-device-firmware__wrapper row">
          <Title titleTranslationId="devices.downloadSoftwareUpdate" defaultMessage="Download Software Update" />
        </div>
        <div className="update-device-firmware">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="update-device-firmware--half">
                <Dropdown
                  className="border"
                  isReadOnly={true}
                  id="brand-selector"
                  selectedItem={this.state.selectedItem}
                  items={this.state.items}
                  labelGetter={i => (i.label.id ? intl.formatMessage(i.label) : i.label)}
                  onSelect={i => {
                    this.setState({ selectedItem: i });
                    if (i.value === '1') {
                      this.getMySystemsFirmwares();
                    } else if (i.value !== '0') {
                      this.props.getFirmwaresByBrand(i.value);
                    }
                  }}
                />
              </div>
              {latestFirmwares.length && this.state.selectedItem.value !== '0' ? (
                <FirmwareDownload firmwares={latestFirmwares} toggleModal={this.toggleModal} isDemo={!!isDemo} />
              ) : (
                loaded &&
                this.state.selectedItem.value !== '0' && (
                  <p>
                    <FormattedMessage
                      id={'devices.updateFirmware.noFirmware'}
                      defaultMessage="No firmwares available."
                    />
                  </p>
                )
              )}
            </>
          )}
        </div>
        <ChangeLogModal toggleModal={this.toggleModal} showModal={this.state.showModal} />
      </div>
    );
  }
}

export default connect(
  ({ firmwares, app: { userInfo, systems, selectedSystem } }) => ({
    ...firmwares,
    userInfo,
    systems,
    selectedSystemBrandId: selectedSystem ? selectedSystem.brandId : null,
  }),
  {
    getFirmwaresByBrand,
    getMyFirmwares,
    getActiveBrands,
  },
)(injectIntl(UpdateDeviceFirmware));
