import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Button } from 'jpi-cloud-web-ui-components';

import { getAzureB2CChangeMFALink } from '../../../../../../api/azureB2C';
import { NotificationManager } from 'react-notifications';

const MFAuthenticatorSection = ({ selectedLanguage }) => {
  const [loading, setLoading] = useState(false);

  const handleMFAChange = async () => {
    const returnUri = window.location.origin + '/profile-settings/security';

    setLoading(true);

    try {
      const mfaChangeUrl = await getAzureB2CChangeMFALink(returnUri, selectedLanguage);
      window.location.assign(mfaChangeUrl);
    } catch (error) {
      NotificationManager.error(
        <FormattedMessage
          id="errors.unexpected-support"
          defaultMessage="Unexpected error occurred, please contact support."
        />,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p>
        <FormattedMessage id="common.mf-authenticator" defaultMessage="Multi - Factor Authenticator" />
      </p>

      <div className="button-wrapper">
        <Button className="button--primary" onClick={handleMFAChange} type="button" disabled={loading}>
          <FormattedMessage id="common.change" defaultMessage="Change" />
        </Button>
      </div>
    </>
  );
};

const stateProps = ({ language: { selectedLanguage } }) => ({ selectedLanguage });

export default connect(stateProps)(MFAuthenticatorSection);
