import * as api from '../../../api';

export const GET_REGISTERED_PRODUCTS_REQUEST = 'productRegistration/GET_REGISTERED_PRODUCTS_REQUEST';
export const GET_REGISTERED_PRODUCTS_RESPONSE = 'productRegistration/GET_REGISTERED_PRODUCTS_RESPONSE';

export const getRegisteredProducts = userId => async dispatch => {
  dispatch({ type: GET_REGISTERED_PRODUCTS_REQUEST });
  const response = await api.getRegisteredProducts(userId);
  if (response.status === 200) {
    dispatch({ type: GET_REGISTERED_PRODUCTS_RESPONSE, productList: response.data });
  } else {
    dispatch({ type: GET_REGISTERED_PRODUCTS_RESPONSE, productList: [] });
  }
};

export const FIND_PRODUCT_REQUEST = 'productRegistration/FIND_PRODUCT_REQUEST';
export const FIND_PRODUCT_RESPONSE = 'productRegistration/FIND_PRODUCT_RESPONSE';

export const findProduct = serialNumber => async dispatch => {
  dispatch({ type: FIND_PRODUCT_REQUEST });
  try {
    const { data } = await api.findProduct(serialNumber);

    dispatch({ type: FIND_PRODUCT_RESPONSE, productFound: data });
    return data?.canBeRegistered;
  } catch (error) {
    dispatch({ type: FIND_PRODUCT_RESPONSE, productFound: { canBeRegistered: false, validCountries: [] } });
    return false;
  }
};

export const REGISTER_PRODUCT_REQUEST = 'productRegistration/REGISTER_PRODUCT_REQUEST';
export const REGISTER_PRODUCT_RESPONSE = 'productRegistration/REGISTER_PRODUCT_RESPONSE';

export const registerProduct = (values, userInfo) => async dispatch => {
  dispatch({ type: REGISTER_PRODUCT_REQUEST });
  const response = await api.registerProduct(values, userInfo);
  if (response.status === 200) {
    dispatch({ type: REGISTER_PRODUCT_RESPONSE, registrationSucceeded: true });
  } else {
    dispatch({ type: REGISTER_PRODUCT_RESPONSE, registrationSucceeded: false });
  }
};

export const REGISTER_PRODUCT_CANCEL = 'productRegistration/REGISTER_PRODUCT_CANCEL';
export const cancelProductRegistration = () => ({ type: REGISTER_PRODUCT_CANCEL });

export const GET_YOUR_TECHNICIANS_LIST_REQUEST = 'productRegistration/GET_YOUR_TECHNICIANS_LIST_REQUEST';
export const GET_YOUR_TECHNICIANS_LIST_RESPONSE = 'productRegistration/GET_YOUR_TECHNICIANS_LIST_RESPONSE';

export const getYourTechniciansList = () => async dispatch => {
  dispatch({ type: GET_YOUR_TECHNICIANS_LIST_REQUEST });
  const response = await api.getYourTechniciansList();
  if (response.status === 200) {
    dispatch({ type: GET_YOUR_TECHNICIANS_LIST_RESPONSE, yourTechnician: response.data });
  } else {
    dispatch({ type: GET_YOUR_TECHNICIANS_LIST_RESPONSE, yourTechnician: {} });
  }
};

export const GET_YOUR_TECHNICIAN_BY_NAME_HINT_REQUEST = 'productRegistration/GET_YOUR_TECHNICIAN_BY_NAME_HINT_REQUEST';
export const GET_YOUR_TECHNICIAN_BY_NAME_HINT_RESPONSE =
  'productRegistration/GET_YOUR_TECHNICIAN_BY_NAME_HINT_RESPONSE';

export const getYourTechnicianByNameHint = value => async dispatch => {
  dispatch({ type: GET_YOUR_TECHNICIAN_BY_NAME_HINT_REQUEST });
  const response = await api.getYourTechnicianByNameHint(value);
  if (response.status === 200) {
    dispatch({ type: GET_YOUR_TECHNICIAN_BY_NAME_HINT_RESPONSE, payload: response.data });
  } else {
    dispatch({ type: GET_YOUR_TECHNICIAN_BY_NAME_HINT_RESPONSE, payload: [] });
  }
};
