import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AccountMigrationSection from './AccountMigrationSection';
import AccountMigrationSuccessModal from '../AccountMigrationModal/AccountMigrationSuccessModal';
import AccountMigrationErrorModal from '../AccountMigrationModal/AccountMigrationErrorModal';
import AccountMigrationModalForm from '../AccountMigrationModal/AccountMigrationModalForms/AccountMigrationModalForm';
import AccountMigrationForgotPasswordModalForm from '../AccountMigrationModal/AccountMigrationModalForms/AccountMigrationForgotPasswordModalForm';
import { useAccountMigration } from '../../../../../services/queries/useAccountMigration';
import { getOldUserTypeObject } from './utils';

import { useForgotOtp } from './hooks/useForgotOtp';
import { useVerifyOtp } from './hooks/useVerifyOtp';
import { useMigrateUserOtp } from './hooks/useMigrateUserOtp';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage } from 'react-intl';
import {
  OtpSentSuccessNotificationBody,
  OtpSentSuccessNotificationHeader,
} from './components/OtpSentSuccessNotification';

const AccountMigrationModalType = {
  FORM: 'form',
  SUCCESS: 'success',
  ERROR: 'error',
  FORGOT_PASSWORD_FORM: 'forgotPasswordForm',
};

const AccountMigration = ({ email, userSite }) => {
  const [openModal, setOpenModal] = useState(null);
  const [error, setError] = useState(null);
  const mutation = useAccountMigration();

  const translationObject = getOldUserTypeObject(userSite);

  const handleOnClose = () => {
    setOpenModal(null);
  };

  const closeSuccessModal = () => {
    handleOnClose();
    window.location.reload(true);
  };

  const onSubmit = ({ password }) => {
    mutation.mutate(
      { password, email },
      {
        onError: () => setOpenModal(AccountMigrationModalType.ERROR),
        onSuccess: () => setOpenModal(AccountMigrationModalType.SUCCESS),
      },
    );
  };

  const { mutate: forgotOtpHandler, isLoading: isForgotOtpLoading, otpSuccess, otpError } = useForgotOtp(email);

  const handleForgotPasswordClick = () => {
    forgotOtpHandler(null, {
      onSuccess: () => setOpenModal(AccountMigrationModalType.FORGOT_PASSWORD_FORM),
      onSettled: () => {
        setError(null);
      },
    });
  };

  const handleResendClick = () => {
    forgotOtpHandler(null, {
      onSuccess: () => {
        NotificationManager.success(
          <FormattedMessage
            id="accountMigrationForgotPasswordModal.resentSuccess"
            defaultMessage="OTP has been sent to your email."
          />,
          <FormattedMessage id="accountMigrationForgotPasswordModal.success.header" defaultMessage="Success" />,
          5000,
        );
      },
      onError: () => {
        NotificationManager.error(
          <FormattedMessage
            id="accountMigrationForgotPasswordModal.error.otpResend"
            defaultMessage="Failed to resend OTP. Please try again."
          />,
          <FormattedMessage id="accountMigrationForgotPasswordModal.error.error" defaultMessage="Error" />,
          5000,
        );
      },
    });
  };

  const { mutate: migrateUserHandler } = useMigrateUserOtp();

  const { mutate: verifyOtpHandler, isLoading: isVerifyOtpLoading } = useVerifyOtp(email, oldUserId => {
    migrateUserHandler(
      { oldUserId },
      {
        onSuccess: () => setOpenModal(AccountMigrationModalType.SUCCESS),
        onError: () => setOpenModal(AccountMigrationModalType.ERROR),
      },
    );
  });

  const onVerifyOtp = ({ password }) => {
    verifyOtpHandler(password, {
      onError: error => {
        const errorMessage = error?.response?.data?.internal_error || 'unknown';
        setError(errorMessage);
      },
    });
  };

  const clearError = () => {
    setError(null);
  };

  const isAnyLoading = isForgotOtpLoading || isVerifyOtpLoading || mutation.isLoading;

  useEffect(() => {
    if (otpSuccess) {
      NotificationManager.success(
        <OtpSentSuccessNotificationBody translationObject={translationObject} />,
        <OtpSentSuccessNotificationHeader />,
        30000,
      );
    }

    if (otpError) {
      NotificationManager.error(
        <FormattedMessage
          id="accountMigrationForgotPasswordModal.error.otpSend"
          defaultMessage="Failed to send OTP. Please try again."
        />,
        <FormattedMessage id="accountMigrationForgotPasswordModal.error.error" defaultMessage="Error" />,
        5000,
      );
    }
  }, [otpSuccess, otpError, translationObject]);

  return (
    <>
      <AccountMigrationSection
        openMigrationModal={() => setOpenModal(AccountMigrationModalType.FORM)}
        translationObject={translationObject}
      />
      <AccountMigrationModalForm
        translationObject={translationObject}
        isOpen={openModal === AccountMigrationModalType.FORM}
        onClose={handleOnClose}
        onSubmit={onSubmit}
        isLoading={mutation.isLoading}
        handleForgotPasswordClick={handleForgotPasswordClick}
      />

      <AccountMigrationForgotPasswordModalForm
        isOpen={openModal === AccountMigrationModalType.FORGOT_PASSWORD_FORM}
        onClose={handleOnClose}
        onSubmit={onVerifyOtp}
        isLoading={isAnyLoading}
        handleResendClick={handleResendClick}
        clearError={clearError}
        error={error}
      />

      <AccountMigrationSuccessModal
        isOpen={openModal === AccountMigrationModalType.SUCCESS}
        onClose={closeSuccessModal}
      />
      <AccountMigrationErrorModal isOpen={openModal === AccountMigrationModalType.ERROR} onClose={handleOnClose} />
    </>
  );
};

AccountMigration.propTypes = {
  email: PropTypes.string,
  userSite: PropTypes.string,
};

export default AccountMigration;
