import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from 'jpi-cloud-web-ui-components';

const RegistrationFormSuccess = ({ onProceed }) => (
  <div className="product-registration step-2 row">
    <div className="col-lg-12">
      <h3>
        <FormattedMessage id="productRegistration.success" defaultMessage="Success" />
      </h3>

      <p>
        <FormattedMessage
          id="productRegistration.successMessage"
          defaultMessage="Thank you for registering your product on myUplink. With your registration, you will have access to the many opportunities to control, improve and monitor your living environment online. Please do not forget to connect your heat pump to the internet and add it to your account. When connecting your heat pump to myUplink you'll be able to monitor your system on any PC, smartphone or tablet using the basic version, free of charge. For a small additional fee you will get access to our premium services which allows you to change settings on your system and monitor your system's operation historically in more detail."
        />
      </p>
      <br />
      <Button className="button--secondary" onClick={onProceed} type="button">
        <FormattedMessage id="button.ok" defaultMessage="OK" />
      </Button>
    </div>
  </div>
);

RegistrationFormSuccess.propTypes = {
  onProceed: PropTypes.func.isRequired,
};

export default RegistrationFormSuccess;
