import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReleaseNotesLink from '../../../ReleaseNotesLink';
import { DemoAccountVerifier } from '../../../layout/DemoAccountVerifier';

const formatDate = date => {
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
};

const FirmwareDownload = ({ firmwares, isDemo }) => {
  return (
    <div className="firmware-info">
      {firmwares.map(fw => {
        const { versions = [] } = fw;
        const [{ version = '', created = '', uri = '', fileName = '' }] = versions;
        return (
          <div className="info-panel" key={version + ':' + created}>
            <br />
            <p>
              <span className="left strong">{(fw.name || fw.firmwareTypeId).toUpperCase()}</span>
              <FormattedMessage id="updateDeviceFirmware.newFirmwareInfo.panel1.version" defaultMessage="Version">
                {p => (
                  <span className="right">
                    {p}: {version}
                  </span>
                )}
              </FormattedMessage>
              <br />
              <span className="left">{fw.description}</span>
              <FormattedMessage
                id="updateDeviceFirmware.newFirmwareInfo.panel1.releaseDate"
                defaultMessage="Release Date"
              >
                {p => (
                  <span className="right">
                    {p}: {formatDate(new Date(created))}
                  </span>
                )}
              </FormattedMessage>
              <br />
              <span className="left">
                <ReleaseNotesLink firmwareTypeId={fw.firmwareTypeId} className="link--basic" />
              </span>
              <span className="right">
                <DemoAccountVerifier position="top">
                  <a href={!isDemo ? uri : '#!'} className={`${isDemo ? 'disabled' : ''} firmware-download-link`}>
                    {fileName} &nbsp;&nbsp;<i className="fa fa-download"></i>
                  </a>
                </DemoAccountVerifier>
              </span>
              <br />
              <br />
            </p>
          </div>
        );
      })}
    </div>
  );
};

FirmwareDownload.propTypes = {
  firmwares: PropTypes.array,
  toggleModal: PropTypes.func.isRequired,
  isDemo: PropTypes.bool,
};

export default FirmwareDownload;
