import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { debounce } from 'lodash';
import { NotificationManager } from 'react-notifications';

import { Button, Input, Checkbox, Spinner } from 'jpi-cloud-web-ui-components';

import { InputDropdown } from '../../../../inputs/Dropdown';

import { formatErrorMessage } from '../../../../../localization/message-formatting';
import { yourTechnicianPattern } from '../../../../constants/constants';

import { errorMessages, registrationFormSchema } from './config';

import './registration-form.scss';
import { useUserInfo } from '../../../../../hooks';
import { DemoAccountVerifier } from '../../../../layout/DemoAccountVerifier';

const getISODate = (dateValue = new Date()) => {
  if (dateValue instanceof Date) {
    const [date /*, time*/] = dateValue.toISOString().split('T');
    return date;
  }

  throw new Error('getISODate - dateValue has to be instande of Date.');
};

const RegistrationForm = ({
  intl,
  countries,
  initialValues,
  toggleWarrantyInfoModal,
  yourTechnician,
  getYourTechnicianByNameHint,
  yourTechnicianNames,
  onSubmit,
  onCancel,
}) => {
  const { isDemo } = useUserInfo();
  const installDateFieldRef = useRef();

  useEffect(() => {
    const today = new Date();

    installDateFieldRef.current.inputRef.current.max = getISODate(today);

    const fiveYearsAgo = new Date(today.setFullYear(today.getFullYear() - 5));

    installDateFieldRef.current.inputRef.current.min = getISODate(fiveYearsAgo);
  }, []);

  const getYourTechnicianByName = debounce(async value => {
    try {
      // TODO: Check if we need to add brand (default="NIBE") to the search
      const data = await getYourTechnicianByNameHint(value);
      return data;
    } catch (error) {
      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );
      return '';
    }
  }, 300);

  return (
    <div className="product-registration step-2">
      <Formik
        initialValues={initialValues}
        countries={countries}
        validationSchema={registrationFormSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, isValid }) => (
          <form onSubmit={handleSubmit} className="product-registration__form">
            <h3>
              <FormattedMessage id="productRegistration.subtitle.1" defaultMessage="Product Information" />
            </h3>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.serialNumber" defaultMessage="Serial Number">
                {placeholder => (
                  <DemoAccountVerifier position="top" tooltipStyle={{ bottom: '70px' }}>
                    <Input
                      name="serialNumber"
                      type="text"
                      placeholder={placeholder}
                      value={values.serialNumber || ''}
                      error={errors.serialNumber ? formatErrorMessage(intl, errorMessages, errors.serialNumber) : ''}
                      onChange={handleChange}
                      readOnly={true}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.productName" defaultMessage="Product Name">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      name="productName"
                      type="text"
                      placeholder={placeholder}
                      value={values.productName || ''}
                      error={errors.productName ? formatErrorMessage(intl, errorMessages, errors.productName) : ''}
                      onChange={handleChange}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.name" defaultMessage="Name">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      name="name"
                      type="text"
                      placeholder={placeholder}
                      value={values.name || ''}
                      error={errors.name ? formatErrorMessage(intl, errorMessages, errors.name) : ''}
                      onChange={handleChange}
                      readOnly={true}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.email" defaultMessage="E-mail">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      name="email"
                      type="text"
                      placeholder={placeholder}
                      value={values.email || ''}
                      error={errors.email ? formatErrorMessage(intl, errorMessages, errors.email) : ''}
                      onChange={handleChange}
                      readOnly={true}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.phone" defaultMessage="Telephone">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      name="phone"
                      type="text"
                      placeholder={placeholder}
                      value={values.phone || ''}
                      error={errors.phone ? formatErrorMessage(intl, errorMessages, errors.phone) : ''}
                      onChange={handleChange}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.operatingHours" defaultMessage="Operating Hours">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      type="text"
                      name="operatingHours"
                      placeholder={placeholder}
                      value={values.operatingHours || ''}
                      error={
                        errors.operatingHours ? formatErrorMessage(intl, errorMessages, errors.operatingHours) : ''
                      }
                      onChange={handleChange}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.installation-date" defaultMessage="Installation Date">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      ref={installDateFieldRef}
                      name="installationDate"
                      type="date"
                      placeholder={placeholder}
                      value={values.installationDate || ''}
                      error={
                        errors.installationDate ? formatErrorMessage(intl, errorMessages, errors.installationDate) : ''
                      }
                      onChange={handleChange}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage
                id="productRegistration.input.installerName"
                defaultMessage="Start typing installer name..."
              >
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <InputDropdown
                      type="text"
                      name="installerName"
                      id={Date.now().toString(36) + Math.random().toString(36).slice(2) + 'installerName'}
                      placeholder={placeholder}
                      error={
                        errors.installerName
                          ? formatErrorMessage(intl, errorMessages, errors.installerName)
                          : yourTechnicianNames.error && !yourTechnician?.id
                          ? intl.formatMessage({
                              id: 'common.tooManyOptions',
                              defaultMessage: 'Please be more specific, too many options',
                            })
                          : ''
                      }
                      selectedItem={values.installerName}
                      items={
                        yourTechnicianNames.data.length > 0 || values.installerName
                          ? yourTechnicianNames.data
                          : yourTechnician
                      }
                      labelGetter={s => s.servicePartnerName}
                      itemsLoading={yourTechnicianNames.loading}
                      onSelect={yourTechnician => {
                        if (typeof yourTechnician !== 'string') {
                          setFieldValue('installerName', yourTechnician.servicePartnerName);
                        }
                      }}
                      onChange={async e => {
                        setFieldValue('installerName', e.target.value);
                        const match = e.target.value.match(yourTechnicianPattern.name.pattern);
                        if (e.target.value.length > 2 && match) {
                          await getYourTechnicianByName(e.target.value);
                        }
                      }}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <h3 className="col-sm-12">
              <FormattedMessage id="productRegistration.subtitle.2" defaultMessage="Product Location" />
            </h3>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.address1" defaultMessage="Address Line 1">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      name="address1"
                      type="text"
                      placeholder={placeholder}
                      value={values.address1 || ''}
                      error={errors.address1 ? formatErrorMessage(intl, errorMessages, errors.address1) : ''}
                      onChange={handleChange}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.address2" defaultMessage="Address Line 2">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      name="address2"
                      type="text"
                      placeholder={placeholder}
                      value={values.address2 || ''}
                      error={errors.address2 ? formatErrorMessage(intl, errorMessages, errors.address2) : ''}
                      onChange={handleChange}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.postalCode" defaultMessage="Zip Code \/ Postal Code">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      name="postalCode"
                      type="text"
                      placeholder={placeholder}
                      value={values.postalCode || ''}
                      error={errors.postalCode ? formatErrorMessage(intl, errorMessages, errors.postalCode) : ''}
                      onChange={handleChange}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.city" defaultMessage="City">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      name="city"
                      type="text"
                      placeholder={placeholder}
                      value={values.city || ''}
                      error={errors.city ? formatErrorMessage(intl, errorMessages, errors.city) : ''}
                      onChange={handleChange}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.region" defaultMessage="State \/ Province \/ Region">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Input
                      name="region"
                      type="text"
                      placeholder={placeholder}
                      value={values.region || ''}
                      error={errors.region ? formatErrorMessage(intl, errorMessages, errors.region) : ''}
                      onChange={handleChange}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-6 registration-form-input">
              <FormattedMessage id="productRegistration.country" defaultMessage="Country">
                {placeholder => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <InputDropdown
                      placeholder={placeholder}
                      selectedItem={values.country || null}
                      id={Date.now().toString(36) + Math.random().toString(36).slice(2) + 'country'}
                      name="country"
                      items={countries}
                      error={errors.country ? formatErrorMessage(intl, errorMessages, errors.country) : ''}
                      labelGetter={e => e.name}
                      onSelect={c => {
                        if (typeof c !== 'string') {
                          setFieldValue('country', c);
                          return;
                        }

                        const matchedCountries =
                          (c &&
                            countries.filter(country => {
                              return country.name.toLowerCase() === c.toLowerCase().trim();
                            })) ||
                          [];
                        matchedCountries.length > 0
                          ? setFieldValue('country', matchedCountries.pop())
                          : setFieldValue('country', null);
                      }}
                      itemFilter={(country, input) => {
                        return !input || country.name.toLowerCase().startsWith(input.toLowerCase().trim());
                      }}
                      isReadOnly
                      disabled
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-12">
              <FormattedMessage
                id="productRegistration.warrantyConditions"
                defaultMessage="I hereby declare that I am the owner of the product and that I've read and understood the warranty conditions"
              >
                {title => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Checkbox
                      id="warrantyConditions"
                      name="warrantyConditions"
                      className="underline"
                      checked={values.warrantyConditions}
                      onChange={handleChange}
                      label={title}
                      onLabelClick={() => toggleWarrantyInfoModal('productRegistration.warrantyConditions.text')}
                      disabled={isDemo}
                      error={
                        errors.warrantyConditions
                          ? formatErrorMessage(intl, errorMessages, errors.warrantyConditions)
                          : ''
                      }
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="col-sm-12">
              <FormattedMessage
                id="productRegistration.dataConsent"
                defaultMessage="I hereby consent to share my system data with myUpTech and all its affiliated companies"
              >
                {title => (
                  <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                    <Checkbox
                      id="dataConsent"
                      name="dataConsent"
                      className="underline"
                      checked={values.dataConsent}
                      onChange={handleChange}
                      label={title}
                      onLabelClick={() => toggleWarrantyInfoModal('privacy-policy')}
                      error={errors.dataConsent ? formatErrorMessage(intl, errorMessages, errors.dataConsent) : ''}
                      disabled={isDemo}
                    />
                  </DemoAccountVerifier>
                )}
              </FormattedMessage>
            </div>
            <div className="button-wrapper col-sm-12">
              <Button className="button--default" type="button" onClick={onCancel}>
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
              <DemoAccountVerifier position="right" tooltipStyle={{ left: '95%' }}>
                <Button
                  className="button--secondary loader-button"
                  type="submit"
                  disabled={isDemo || isSubmitting || !isValid}
                >
                  <FormattedMessage id="productRegistration.register" defaultMessage="Register" />
                  {isSubmitting && <Spinner />}
                </Button>
              </DemoAccountVerifier>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

RegistrationForm.propTypes = {
  initialValues: PropTypes.object,
  countries: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  toggleWarrantyInfoModal: PropTypes.func.isRequired,
  yourTechnician: PropTypes.array.isRequired,
  getYourTechnicianByNameHint: PropTypes.func.isRequired,
  yourTechnicianNames: PropTypes.object.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(RegistrationForm);
