import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Input } from 'jpi-cloud-web-ui-components';
import { InputDropdown } from '../../../../inputs/Dropdown';
import { formatErrorMessage } from '../../../../../localization/message-formatting';
import { noRegion } from '../../../../constants/constants';
import { DemoAccountVerifier } from '../../../../layout/DemoAccountVerifier';
import { useUserInfo } from '../../../../../hooks';

const errorMessages = {
  'country.required': {
    id: 'country.error.validation.required',
    defaultMessage: 'Country is mandatory field',
  },
  'city.required': {
    id: 'city.error.validation.required',
    defaultMessage: 'City is mandatory field',
  },
  'city.invalid-format': {
    id: 'city.error.validation.invalid-format',
    defaultMessage: 'City has invalid format',
  },
  'city.max-length': {
    id: 'city.error.validation.maxlength',
    defaultMessage: 'City cannot be longer than 100 characters',
  },
  'address.line-one.required': {
    id: 'address.error.validation.line-one.required',
    defaultMessage: 'Address line 1 is required',
  },
  'address.line-one.invalid-format': {
    id: 'address.error.validation.line-one.invalid-format',
    defaultMessage: 'Address line 1 has invalid format',
  },
  'address.line-one.max-length': {
    id: 'address.error.validation.line-one.maxlength',
    defaultMessage: 'Address line 1 cannot be longer than 255 characters',
  },
  'address.line-two.invalid-format': {
    id: 'address.error.validation.line-two.invalid-format',
    defaultMessage: 'Address line 2 has invalid format',
  },
  'address.line-two.max-length': {
    id: 'address.error.validation.line-two.maxlength',
    defaultMessage: 'Address line 2 cannot be longer than 255 characters',
  },
  'postal-code.maxlength': {
    id: 'postal-code.error.validation.maxlength',
    defaultMessage: 'Postal code cannot be longer than 20 characters',
  },
  'postal-code.required': {
    id: 'postal-code.error.validation.required',
    defaultMessage: 'Postal code is mandatory field',
  },
  'postal-code.regexp': {
    id: 'postal-code.error.validation.regexp',
    defaultMessage: 'Postal code has an invalid format',
  },
  'region.required': {
    id: 'region.error.validation.required',
    defaultMessage: 'Region is mandatory field',
  },
  'region.invalid-format': {
    id: 'region.error.validation.invalid-format',
    defaultMessage: 'Region has invalid format',
  },
  'region.max-length': {
    id: 'region.error.validation.maxlength',
    defaultMessage: 'Region cannot be longer than 100 characters',
  },
};

const EditAddressInfo = props => {
  const { isDemo } = useUserInfo();
  const selectedCountryRegion =
    props.values.region &&
    props.values.country &&
    props.values.country.regions.filter(region => {
      return region.name.toLowerCase() === props.values.region.toLowerCase().trim();
    })[0];

  return (
    <>
      <input type="hidden" id={props.values.id} value={props.values.id} />
      <div className="address-row">
        <DemoAccountVerifier position="top" tooltipStyle={{ bottom: '60px', left: '75px' }}>
          <Input
            placeholder={props.intl.formatMessage({ id: 'label.addressLine1', defaultMessage: 'Address Line One' })}
            value={props.values.lineOne || ''}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="text"
            name="lineOne"
            error={props.errors.lineOne ? formatErrorMessage(props.intl, errorMessages, props.errors.lineOne) : ''}
            disabled={isDemo}
          />
        </DemoAccountVerifier>
        <DemoAccountVerifier position="top" tooltipStyle={{ bottom: '60px', left: '75px' }}>
          <Input
            placeholder={props.intl.formatMessage({ id: 'label.addressLine2', defaultMessage: 'Address Line Two' })}
            value={props.values.lineTwo || ''}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="text"
            name="lineTwo"
            error={props.errors.lineTwo ? formatErrorMessage(props.intl, errorMessages, props.errors.lineTwo) : ''}
            disabled={isDemo}
          />
        </DemoAccountVerifier>
      </div>
      <div className="address-row">
        <DemoAccountVerifier position="top" tooltipStyle={{ bottom: '60px', left: '75px' }}>
          <Input
            placeholder={props.intl.formatMessage({ id: 'label.city', defaultMessage: 'City' })}
            value={props.values.city || ''}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="text"
            name="city"
            error={props.errors.city ? formatErrorMessage(props.intl, errorMessages, props.errors.city) : ''}
            disabled={isDemo}
          />
        </DemoAccountVerifier>
        <DemoAccountVerifier position="top" tooltipStyle={{ bottom: '60px', left: '75px' }}>
          <Input
            placeholder={props.intl.formatMessage({ id: 'label.postalCode', defaultMessage: 'Postal Code' })}
            value={props.values.postalCode || ''}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="text"
            name="postalCode"
            error={
              props.errors.postalCode ? formatErrorMessage(props.intl, errorMessages, props.errors.postalCode) : ''
            }
            disabled={isDemo}
          />
        </DemoAccountVerifier>
      </div>
      <div className="address-row">
        <DemoAccountVerifier position="top" tooltipStyle={{ bottom: '60px', left: '75px' }}>
          <InputDropdown
            placeholder={props.intl.formatMessage({ id: 'label.country', defaultMessage: 'Country' })}
            selectedItem={props.values.country || null}
            label={(props.values.country && props.values.country.name) || ''}
            id={Date.now().toString(36) + Math.random().toString(36).substr(2)}
            name="country"
            items={props.countries}
            error={props.errors.country ? formatErrorMessage(props.intl, errorMessages, props.errors.country) : ''}
            labelGetter={e => e.name}
            disabled={isDemo}
            onSelect={c => {
              props.setFieldTouched('region', false);
              props.setFieldValue('region', '');
              if (typeof c !== 'string') {
                const selectedCountry = c.regions.length > 0 ? { ...c, regions: [noRegion, ...c.regions] } : c;
                props.setFieldValue('country', selectedCountry);
                return;
              }

              const matchedCountries =
                (c &&
                  props.countries.filter(country => {
                    return country.name.toLowerCase() === c.toLowerCase().trim();
                  })) ||
                [];
              if (matchedCountries.length > 0) {
                const country = matchedCountries.pop();
                const selectedCountry =
                  country.regions.length > 0 ? { ...country, regions: [noRegion, ...country.regions] } : country;
                props.setFieldValue('country', selectedCountry);
              } else {
                props.setFieldValue('country', null);
              }
            }}
            itemFilter={(country, input) => {
              return !input || country.name.toLowerCase().startsWith(input.toLowerCase().trim());
            }}
          />
        </DemoAccountVerifier>
        {props.values.country && props.values.country.regions.length > 0 ? (
          <DemoAccountVerifier position="top" tooltipStyle={{ bottom: '60px', left: '75px' }}>
            <InputDropdown
              placeholder={props.intl.formatMessage({ id: 'label.region', defaultMessage: 'Region' })}
              selectedItem={selectedCountryRegion || props.values.region}
              id={Date.now().toString(36) + Math.random().toString(36).substr(2)}
              name="region"
              items={props.values.country.regions}
              disabled={isDemo}
              error={
                props.errors.region && props.touched.region
                  ? formatErrorMessage(props.intl, errorMessages, props.errors.region)
                  : ''
              }
              labelGetter={e => e.name}
              onSelect={c => {
                if (typeof c !== 'string') {
                  props.setFieldValue('region', c.name);
                  return;
                }
                const matchedRegion =
                  (c &&
                    props.values.country.regions.filter(region => {
                      return region.name.toLowerCase() === c.toLowerCase().trim();
                    })) ||
                  [];
                matchedRegion.length > 0
                  ? props.setFieldValue('region', matchedRegion.pop().name)
                  : props.setFieldValue('region', '');
              }}
              itemFilter={(region, input) => {
                return !input || region.name.toLowerCase().startsWith(input.toLowerCase().trim());
              }}
            />
          </DemoAccountVerifier>
        ) : (
          <DemoAccountVerifier position="top" tooltipStyle={{ bottom: '60px', left: '75px' }}>
            <Input
              placeholder={props.intl.formatMessage({ id: 'label.region', defaultMessage: 'Region' })}
              value={props.values.region || ''}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              type="text"
              name="region"
              disabled={isDemo}
              error={
                props.errors.region && props.touched.region
                  ? formatErrorMessage(props.intl, errorMessages, props.errors.region)
                  : ''
              }
            />
          </DemoAccountVerifier>
        )}
      </div>
      {props.requestError && (
        <p className="text-danger">{formatErrorMessage(props.intl, errorMessages, props.requestError)}</p>
      )}
    </>
  );
};

EditAddressInfo.propTypes = {
  addressId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  address: PropTypes.object,
  requestError: PropTypes.string,
  intl: PropTypes.object,
  countries: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default injectIntl(EditAddressInfo);
