import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Spinner } from 'jpi-cloud-web-ui-components';
import Dropdown from '../../../../inputs/Dropdown';
import '../../../../inputs/Dropdown/Dropdown.scss';
import ToggleSwitch from '../../../../inputs/ToggleSwitch';

import { getScheduleModeStatus, setScheduleOverride, setScheduleOverrideMode } from '../../actions';
import { DemoAccountVerifier } from '../../../../layout/DemoAccountVerifier';

class ToggleScheduleOverride extends React.Component {
  static propTypes = {
    devices: PropTypes.array.isRequired,
    modes: PropTypes.array.isRequired,
    scheduleOverride: PropTypes.object,
    userIsViewer: PropTypes.bool.isRequired,

    getScheduleModeStatus: PropTypes.func.isRequired,
    setScheduleOverride: PropTypes.func.isRequired,
    setScheduleOverrideMode: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await this.props.getScheduleModeStatus(this.props.devices[0].id);
    this.setState({ loading: false });
  }

  toggleOverride = async checked => {
    const { devices, scheduleOverride, setScheduleOverride } = this.props;
    this.setState({ loading: true });
    await setScheduleOverride(devices[0].id, { ...scheduleOverride, active: !!checked });
    this.setState({ loading: false });
  };

  onModeChange = async mode => {
    const { devices, scheduleOverride, setScheduleOverride } = this.props;
    this.props.setScheduleOverrideMode(mode.modeId);
    if (scheduleOverride.active) {
      this.setState({ loading: true });
      await setScheduleOverride(devices[0].id, { ...scheduleOverride, modeId: mode.modeId });
      this.setState({ loading: false });
    }
  };

  render() {
    const { modes, scheduleOverride, userIsViewer } = this.props;
    const { loading } = this.state;

    return (
      <div className="schedule-override">
        {loading && <Spinner />}
        {!loading && (
          <Fragment>
            {' '}
            <div className="control-row">
              <div className="text">
                <FormattedMessage id="schedule-override.override-label" defaultMessage="Override">
                  {txt => <label>{txt}</label>}
                </FormattedMessage>
              </div>
              {modes.length > 1 && (
                <ToggleSwitch
                  isChecked={scheduleOverride.active}
                  onChange={this.toggleOverride}
                  isDisabledAndVisibleTooltip={userIsViewer}
                  premiumFeatureType="permissionAccess"
                />
              )}
            </div>
            <div className="control-row opacity">
              <div className="text opacity">
                <FormattedMessage id="schedule-override.mode-label" defaultMessage="Mode">
                  {txt => <label>{txt}</label>}
                </FormattedMessage>
              </div>
              <DemoAccountVerifier position="right" tooltipStyle={{ top: '30px' }}>
                <Dropdown
                  id={(scheduleOverride && scheduleOverride.modeId && scheduleOverride.modeId.toString()) || ''}
                  items={modes}
                  selectedItem={modes.find(m => m.modeId === scheduleOverride.modeId)}
                  labelGetter={item => item.name}
                  onSelect={item => this.onModeChange(item)}
                  disabled={userIsViewer}
                />
              </DemoAccountVerifier>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default connect(
  ({ devices: { devices }, deviceScheduling: { modes, scheduleOverride } }) => ({
    modes,
    devices,
    scheduleOverride,
  }),
  {
    getScheduleModeStatus,
    setScheduleOverride,
    setScheduleOverrideMode,
  },
)(ToggleScheduleOverride);
