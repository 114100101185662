import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import { formatErrorMessage } from '../../../../localization/message-formatting';
import { DemoAccountVerifier } from '../../../layout/DemoAccountVerifier';

const validateTokenSchema = yup.object().shape({
  serialNumber: yup.string().trim().required('serial-number.required'),
  connectionString: yup.string().trim().required('connection-string.required'),
});

const errorMessages = {
  'serial-number.required': {
    id: 'serial-number.error.validation.required',
    defaultMessage: 'Serial number is a mandatory field',
  },
  'connection-string.required': {
    id: 'add-device.error.validation.connection-string.required',
    defaultMessage: 'Connection string is a mandatory field',
  },
};

const ValidateTokenForm = ({ onSubmit, loading, onCancel, error, isDemoUser, intl }) => {
  return (
    <Formik
      initialValues={{
        serialNumber: '',
        connectionString: '',
      }}
      validationSchema={validateTokenSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleChange, handleBlur, touched, handleSubmit, isSubmitting }) => (
        <div className="form-wrapper">
          <form onSubmit={handleSubmit} className="form--half" autoComplete="off">
            <FormattedMessage id="addDevice.form.serialNumber" defaultMessage="Serial Number">
              {placeholder => (
                <DemoAccountVerifier
                  position="right"
                  style={{ width: '100%' }}
                  tooltipStyle={{ top: '47%', left: '102%' }}
                >
                  <Input
                    placeholder={placeholder}
                    type="text"
                    name="serialNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.serialNumber}
                    disabled={isDemoUser}
                    error={
                      errors.serialNumber &&
                      touched.serialNumber &&
                      formatErrorMessage(intl, errorMessages, errors.serialNumber)
                    }
                  />
                </DemoAccountVerifier>
              )}
            </FormattedMessage>
            <FormattedMessage id="addDevice.form.connectionString" defaultMessage="Connection string">
              {placeholder => (
                <DemoAccountVerifier
                  position="right"
                  style={{ width: '100%' }}
                  tooltipStyle={{ top: '47%', left: '102%' }}
                >
                  <Input
                    placeholder={placeholder}
                    type="text"
                    name="connectionString"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.connectionString}
                    disabled={isDemoUser}
                    error={
                      errors.connectionString &&
                      touched.connectionString &&
                      formatErrorMessage(intl, errorMessages, errors.connectionString)
                    }
                  />
                </DemoAccountVerifier>
              )}
            </FormattedMessage>

            {error && <p className="error"> {<FormattedMessage id={error} defaultMessage="Device not found" />}</p>}

            <div className="button-wrapper">
              <DemoAccountVerifier position="top" tooltipStyle={{ left: 'unset', bottom: '115%' }}>
                <Button className="button--secondary" type="submit" disabled={isDemoUser || loading || isSubmitting}>
                  <FormattedMessage id="addDevice.form.validateToken" defaultMessage="Validate token" />
                </Button>
              </DemoAccountVerifier>
              <Button className="button--default" type="button" onClick={onCancel} disabled={loading || isSubmitting}>
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

ValidateTokenForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  intl: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  isDemoUser: PropTypes.bool,
};

export default injectIntl(ValidateTokenForm);
