import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'jpi-cloud-web-ui-components';

const AccountNigrationModalSpinner = ({ isLoading }) =>
  isLoading ? (
    <div className="account-migration-modal-button-spinner">
      <Spinner />
    </div>
  ) : null;

AccountNigrationModalSpinner.propTypes = {
  isLoading: PropTypes.bool,
};

export default AccountNigrationModalSpinner;
