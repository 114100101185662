import React, { useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';

import { Button } from 'jpi-cloud-web-ui-components';

import { eventEmitter, events } from '../../../../../tracking/AppInsights';

import { history } from '../../../../../../store/configureStore';

import Spacer from '../../../../../layout/Spacer';

const AddressInformationError = new Error('Address Information Missing');
AddressInformationError.name = 'AddressInformationError';
AddressInformationError.cause = 'unsupportedCountry';

const UnavailableServicesError = new Error('Unavailable Service');
UnavailableServicesError.name = 'UnavailableServicesError';
UnavailableServicesError.cause = 'error.countryNotSupported';

const SubscriptionError = ({ errorData }) => {
  const errorNotes = {
    [AddressInformationError.name]: (
      <FormattedMessage
        id="shop.page.askToUpdateAddress"
        defaultMessage="Please go to profile settings and update your address information to enable subscriptions."
      />
    ),
    [UnavailableServicesError.name]: (
      <FormattedMessage
        id="shop.page.notSupportedCountryForUser"
        defaultMessage="Currently, we do not provide services of NIBE / CLM in your country."
      />
    ),
  };

  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    try {
      const parsedError = JSON.parse(errorData?.internal_error);

      if (parsedError?.error === AddressInformationError.cause) setServerError(AddressInformationError);

      if (parsedError?.data?.key === UnavailableServicesError.cause) setServerError(UnavailableServicesError);
    } catch (error) {
      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );

      eventEmitter.emit(events.appInsights.logError, error);
    }
  }, [errorData]);

  return (
    <>
      <h4 className={'Product__error'}>
        <FormattedMessage
          id="shop.page.subscriptionsError"
          defaultMessage="An error occurred while loading subscriptions."
        />
      </h4>

      <p>{errorNotes[serverError?.name]}</p>

      <Spacer y={30} />
      {serverError?.name === AddressInformationError.name && (
        <Button className="button--primary" onClick={() => history.push('/profile-settings')}>
          Profile Settings
        </Button>
      )}
    </>
  );
};

export default SubscriptionError;
