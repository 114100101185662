import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TermsOfServiceModal from '../../../Cart/TermsOfServiceModal';
import { Button, Checkbox } from 'jpi-cloud-web-ui-components';
import { isStripeSpecificBrand as isStripeBrand, redirectUserToPaymentWindow } from '../../../storeUtils';
import './addcard.scss';
import { useUserInfo } from '../../../../../../../hooks';
import { DemoAccountVerifier } from '../../../../../../layout/DemoAccountVerifier';

const addCard = ({
  language,
  cardURLDetails,
  paymentProviders,
  agreementAccepted,
  setAgreementAccepted,
  selectedSystem,
  paidBrands,
  isAddCardButtonDisabled,
}) => {
  const { isDemo } = useUserInfo();
  const [showTermsOfService, setTermsOfService] = useState(false);
  const { brandId = '' } = selectedSystem || {};
  const { stripeSpecificPaidBrands } = paidBrands;
  const isStripeSpecificBrand = isStripeBrand(stripeSpecificPaidBrands, brandId);

  const toggleTermsOfService = () => {
    setTermsOfService(open => !open);
  };

  const addCard = () => {
    const [{ internalName = '' } = {}] = paymentProviders;
    const { data = {} } = cardURLDetails;
    const { hostedPaymentPageUrl = '' } = data;
    const { languageTag = '' } = language;
    if (hostedPaymentPageUrl) {
      setAgreementAccepted(!agreementAccepted);
      redirectUserToPaymentWindow(hostedPaymentPageUrl, languageTag, internalName);
    }
  };

  return (
    <>
      <div className="AddCard">
        <div className="AddCard__descriptionWrapper">
          <FormattedMessage
            id="paymentProviders.settingsPage.futurePayment"
            defaultMessage="You need to add a credit{debit} card for future payment of the subscription."
            values={{
              debit: isStripeSpecificBrand ? '/debit' : '',
            }}
          />
        </div>
        <p className="AddCard__noCardMessage">
          <FormattedMessage id="paymentProviders.dibs-ticket.noCardAdded" defaultMessage="No card added" />
        </p>
        {!isStripeSpecificBrand && (
          <div className="AddCard__checkbox-wrapper">
            <DemoAccountVerifier position="top" tooltipStyle={{ left: '-70px' }}>
              <Checkbox
                id="acceptedTerms"
                name="acceptedTerms"
                checked={agreementAccepted}
                disabled={isDemo}
                onChange={e => setAgreementAccepted(e.target.checked)}
                className="underline"
                label={<FormattedMessage id="register.user-terms" defaultMessage="I accept the terms of service" />}
                onLabelClick={() => toggleTermsOfService()}
              />
            </DemoAccountVerifier>
          </div>
        )}
        <div className="AddCard__buttonWrapper">
          <DemoAccountVerifier position="top" tooltipStyle={{ bottom: '75px' }}>
            <Button
              className="link--secondary AddCard__addCard-button"
              onClick={() => {
                addCard();
              }}
              disabled={isDemo || (!agreementAccepted && !isStripeSpecificBrand) || isAddCardButtonDisabled}
            >
              <FormattedMessage id="paymentProviders.dibs-ticket.addNewCard" defaultMessage="Add card" />
            </Button>
          </DemoAccountVerifier>
        </div>
      </div>
      <TermsOfServiceModal isTermsOfService={showTermsOfService} toggleTermsOfService={toggleTermsOfService} />
    </>
  );
};

addCard.propTypes = {
  language: PropTypes.shape({
    languageTag: PropTypes.string.isRequired,
  }),
  paymentProviders: PropTypes.arrayOf(
    PropTypes.shape({
      internalName: PropTypes.string.isRequired,
    }),
  ),
  cardURLDetails: PropTypes.shape({
    data: PropTypes.shape({
      hostedPaymentPageUrl: PropTypes.string.isRequired,
    }),
  }),
  currencyCode: PropTypes.string.isRequired,
  agreementAccepted: PropTypes.bool.isRequired,
  setAgreementAccepted: PropTypes.func.isRequired,
  selectedSystem: PropTypes.shape({
    brandId: PropTypes.string.isRequired,
  }),
  paidBrands: PropTypes.shape({
    stripeSpecificPaidBrands: PropTypes.array,
  }),
  isAddCardButtonDisabled: PropTypes.bool,
};

const mapStateToProps = state => ({
  cardURLDetails: state.shop.cardURLDetails,
  paymentProviders: state.shop.paymentProviders,
  language: state.language,
  selectedSystem: state.app.selectedSystem,
  paidBrands: state.shop.paidBrands,
});

export default connect(mapStateToProps)(addCard);
