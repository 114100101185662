import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../layout/Modal';

import {
  discardEditing,
  saveTile,
  selectTileType,
  startTileCreation,
  setDisplayParameter,
  removeDisplayParameter,
  setChartValue,
  removeChartValue,
  setTitle,
  getTileParameters,
  setSettingsParameters,
} from './actions';
import {
  isSelectTileTypeEditorShown,
  isPopupShown,
  isTilePropertyEditorShown,
  getDisplayParameter,
  getSettingParameter,
  getChartValue,
  getCurrentTileType,
  getTitle,
  getCurrentTileNameTextId,
} from './reducers';
import './tile-configuration.scss';

import SelectTileType from './SelectTileType';
import TilePropertyEditor from './TilePropertyEditor';

const TileConfigurationPopup = ({
  configurationState,
  discardEditing,
  selectTileType,
  setTitle,
  setDisplayParameter,
  removeDisplayParameter,
  setChartValue,
  removeChartValue,
  saveTile,
  startTileCreation,
  getTileParameters,
  selectedSystem,
  devices,
  setSettingsParameters,
  tilesData,
  isDemoUser,
}) => {
  const filteredDependsOnConfiguration = {
    ...configurationState,
    tileParameters: configurationState.tileParameters,
  };

  return (
    <div>
      <Modal show={isPopupShown(filteredDependsOnConfiguration)} backdrop="static" onHide={discardEditing}>
        {isSelectTileTypeEditorShown(filteredDependsOnConfiguration) &&
          filteredDependsOnConfiguration.isNewTileCreation && (
            <SelectTileType
              onCancel={discardEditing}
              onTypeSelected={selectTileType}
              tileTypes={filteredDependsOnConfiguration.tileTypes}
              devices={devices}
            />
          )}

        {isTilePropertyEditorShown(filteredDependsOnConfiguration) && (
          <TilePropertyEditor
            onSubmit={() => saveTile()}
            getTitle={getTitle(filteredDependsOnConfiguration)}
            getChartValue={() => getChartValue(filteredDependsOnConfiguration)}
            getSettingParameter={getSettingParameter(filteredDependsOnConfiguration)}
            getDisplayParameter={getDisplayParameter(filteredDependsOnConfiguration)}
            setTitle={setTitle}
            setChartValue={setChartValue}
            removeChartValue={removeChartValue}
            setDisplayParameter={setDisplayParameter}
            removeDisplayParameter={removeDisplayParameter}
            properties={filteredDependsOnConfiguration.editState.properties}
            setSettingsParameters={setSettingsParameters}
            onBack={filteredDependsOnConfiguration.isNewTileCreation ? startTileCreation : discardEditing}
            tileType={getCurrentTileType(filteredDependsOnConfiguration)}
            tileNameTextId={getCurrentTileNameTextId(filteredDependsOnConfiguration)}
            getTileParameters={getTileParameters}
            tileParameters={filteredDependsOnConfiguration.tileParameters}
            groupId={selectedSystem.id}
            loading={filteredDependsOnConfiguration.loading || false}
            tilesData={tilesData}
            isDemoUser={isDemoUser}
          />
        )}
      </Modal>
    </div>
  );
};

TileConfigurationPopup.propTypes = {
  discardEditing: PropTypes.func,
  selectTileType: PropTypes.func,
  saveTile: PropTypes.func,
  setTitle: PropTypes.func.isRequired,
  setDisplayParameter: PropTypes.func.isRequired,
  removeDisplayParameter: PropTypes.func.isRequired,
  setChartValue: PropTypes.func.isRequired,
  removeChartValue: PropTypes.func.isRequired,
  startTileCreation: PropTypes.func,
  getTileParameters: PropTypes.func.isRequired,
  configurationState: PropTypes.object,
  selectedSystem: PropTypes.object,
  tilesData: PropTypes.object,
  devices: PropTypes.array,
  setSettingsParameters: PropTypes.func.isRequired,
  isDemoUser: PropTypes.bool,
};

const mapStateToProps = ({
  tileConfigurationPopup,
  app: { selectedSystem, userInfo },
  devices: { devices },
  tilesData,
}) => ({
  configurationState: tileConfigurationPopup,
  selectedSystem,
  devices,
  tilesData,
  isDemoUser: userInfo.isDemo,
});

export default connect(mapStateToProps, {
  discardEditing,
  saveTile,
  selectTileType,
  startTileCreation,
  setTitle,
  setDisplayParameter,
  removeDisplayParameter,
  setChartValue,
  removeChartValue,
  getTileParameters,
  setSettingsParameters,
})(TileConfigurationPopup);
