import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Input, Title } from 'jpi-cloud-web-ui-components';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import * as yup from 'yup';
import { Formik } from 'formik';
import { redeemVoucher, clearErrorMessage } from './actions';
import { getDevices, getSubscriptionsInGroup } from '../Devices/actions';
import { getUserSubscriptions } from '../../FeaturesFlags/actions';
import { USER_SECURITY_LEVELS } from '../SystemProfile/components/SecurityTab/costants';

import './redeemVoucher.scss';
import 'react-notifications/lib/notifications.css';
import { formatErrorMessage } from '../../../localization/message-formatting';
import { DemoAccountVerifier } from '../../layout/DemoAccountVerifier';

const schema = yup.object().shape({
  voucherCode: yup.string().trim().required('voucher-code.required'),
});

const errorMessages = {
  'voucher-code.required': {
    id: 'redeemVoucher.FieldValidationError',
    defaultMessage: 'Voucher code is required',
  },
};

class RedeemVoucher extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    history: PropTypes.object,
    redeemVoucherModel: PropTypes.object,
    error: PropTypes.string,
    selectedSystem: PropTypes.string,
    intl: PropTypes.object,
    redeemVoucher: PropTypes.func.isRequired,
    clearErrorMessage: PropTypes.func.isRequired,
    getDevices: PropTypes.func.isRequired,
    getUserSubscriptions: PropTypes.func.isRequired,
    getSubscriptionsInGroup: PropTypes.func.isRequired,
    userInfo: PropTypes.object,
  };

  state = {
    displayErrorMessage: false,
    displaySuccessMessage: false,
  };

  checkIsAdmin = () => {
    const isSystemAdmin = this.props.selectedSystem && this.props.selectedSystem.role !== USER_SECURITY_LEVELS.admin;
    return isSystemAdmin ? this.props.history.push('/') : null;
  };

  componentDidMount() {
    this.checkIsAdmin();
  }

  componentDidUpdate() {
    this.checkIsAdmin();
  }

  componentWillUnmount() {
    this.props.clearErrorMessage();
  }

  onSubmit = async values => {
    this.setState({ displayErrorMessage: false, displaySuccessMessage: false });
    const systemId = this.props.selectedSystem ? this.props.selectedSystem.id : null;
    await this.props.redeemVoucher(systemId, values.voucherCode.trim());

    if (this.props.error) {
      this.setState({ ...this.state, displayErrorMessage: true });
    } else {
      this.setState({ ...this.state, displaySuccessMessage: true });
      var devices = await this.props.getDevices(systemId);
      if (devices && devices.length > 0) {
        await this.props.getUserSubscriptions(devices[0].id);
        await this.props.getSubscriptionsInGroup(systemId);
      }
    }
  };

  render() {
    const { loading, userInfo, intl } = this.props;
    const { displayErrorMessage, displaySuccessMessage } = this.state;
    const isDemo = userInfo && userInfo.isDemo;

    return (
      <div className="page-content">
        <div className="redeemVoucher">
          <Title titleTranslationId="redeemVoucher.Title" />
          {displaySuccessMessage ? (
            <section className="success-message-section">
              <h2>
                <FormattedMessage id="redeemVoucher.Success" defaultMessage="Success" />
              </h2>
              <FormattedHTMLMessage
                id="redeemVoucher.VoucherRedeemed"
                defaultMessage="The content of your voucher code has been added to this system's premium subscriptions. If you have already subscribed to any of the services, your current subscriptions have automatically been extended."
              />
            </section>
          ) : (
            <React.Fragment>
              <section className="redeem-voucher-form">
                <div className="page-description">
                  <FormattedMessage
                    id="redeemVoucher.Description"
                    defaultMessage="If you have received a coupon code for premium subscription for myUplink, you can redeem it by entering it below. The coupon unlocks the premium features for this system for a limited time, details are available in “Subscriptions” section."
                  />
                </div>
                <div>
                  <Formik
                    initialValues={{
                      voucherCode: '',
                    }}
                    validationSchema={schema}
                    onSubmit={this.onSubmit}
                  >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <DemoAccountVerifier position="right" tooltipStyle={{ left: '105%', top: '40px' }}>
                          <Input
                            placeholder={this.props.intl.formatMessage({ id: 'redeemVoucher.VoucherCodePlaceholder' })}
                            type="voucherCode"
                            name="voucherCode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.voucherCode}
                            error={
                              errors.voucherCode &&
                              touched.voucherCode &&
                              formatErrorMessage(intl, errorMessages, errors.voucherCode)
                            }
                            readOnly={isDemo}
                            disabled={isDemo}
                          />
                        </DemoAccountVerifier>
                        <div className="button-wrapper">
                          <DemoAccountVerifier position="right" tooltipStyle={{ left: '95%' }}>
                            <Button className="button--secondary" type="submit" disabled={isDemo || loading}>
                              <FormattedMessage id="redeemVoucher.Title" defaultMessage="Redeem Voucher" />
                            </Button>
                          </DemoAccountVerifier>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </section>
              {displayErrorMessage && (
                <section className="error-message-section">
                  <h3>
                    <FormattedMessage id="redeemVoucher.Error" defaultMessage="Error" />: &nbsp;
                    <FormattedMessage
                      id="redeemVoucher.Error.InvalidVoucherCode"
                      defaultMessage="Invalid voucher code"
                    />
                  </h3>
                </section>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  ({ app: { selectedSystem, userInfo }, redeemVoucher }) => ({
    selectedSystem: selectedSystem,
    ...redeemVoucher,
    userInfo,
  }),
  {
    redeemVoucher,
    getDevices,
    getUserSubscriptions,
    getSubscriptionsInGroup,
    clearErrorMessage,
  },
)(injectIntl(RedeemVoucher));
