import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export const OtpSentSuccessNotificationBody = ({ translationObject }) => (
  <>
    <FormattedMessage
      id={`accountMigrationForgotPasswordModal.sentSuccess.body1${translationObject.key}`}
      defaultMessage={`A one-time password has been sent to the email address associated with your old ${translationObject.value} account.`}
    />
    <br />
    <FormattedMessage
      id="accountMigrationForgotPasswordModal.sentSuccess.body2"
      defaultMessage="Please use the password provided in this email to complete the migration process."
    />
  </>
);

OtpSentSuccessNotificationBody.propTypes = {
  translationObject: PropTypes.object.isRequired,
};

export const OtpSentSuccessNotificationHeader = () => (
  <FormattedMessage
    id="accountMigrationForgotPasswordModal.sentSuccess.header"
    defaultMessage="An e-mail has been sent"
  />
);
