import { Spinner, Title } from 'jpi-cloud-web-ui-components';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes, { string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { USER_SECURITY_LEVELS } from '../../../SystemProfile/components/SecurityTab/costants';
import { isStripeSpecificBrand as isStripeBrand } from '../storeUtils';
import CartError from './components/CartError';
import NoPremiumAvailable from './components/NoPremiumAvailable';
import PremiumAvailable from './components/PremiumAvailable';
import ProductsError from './components/ProductsError';
import SubscriptionFailureMessage from './components/SubscriptionFailureMessage';
import YearlySubscription from './components/YearlySubscription';
import SubscriptionError from './components/SubscriptionError';

import './products.scss';

const SubscriptionStatus = {
  FAILED: 'failed',
  ACTIVE: 'active',
  CANCELED: 'canceled',
  TRIAL: 'trial',
};

const Products = ({
  userSubscriptions,
  cart: { init, loading, error, lineItems },
  addToCart,
  products: { currencyCode },
  isDemo,
  supportedCountries,
  hasRightsToBuy,
  isShopAvailableForUser,
  isShopAvailableForSystem,
  isLoadingSubscriptions,
  hasErrorFetchingSubscription,
  selectedSystem,
  stripeSpecificBrandList,
  isPaidBrand,
}) => {
  const hasPremiumFeature =
    userSubscriptions?.filter(sub => sub.subscription && moment(sub.subscription.validUntil).isAfter()).length > 0;
  const hasDeviceSubscription = !!userSubscriptions?.length;
  const isShopSupportedByUser = !isLoadingSubscriptions && !isShopAvailableForUser;
  const isShopSupportedBySystem = !isLoadingSubscriptions && !isShopAvailableForSystem;
  const showSubscription = (isShopAvailableForUser && isShopAvailableForSystem) || !supportedCountries.loaded;
  const isSubscriptionAvailable = hasRightsToBuy && hasDeviceSubscription && !isLoadingSubscriptions;
  localStorage.setItem('IS_SUBSCRIPTION_VALID', false);
  const userIsViewer = selectedSystem && selectedSystem.userRole === USER_SECURITY_LEVELS.viewer;
  const viewerRightsError =
    'The subject does not have any of the allowed access grants (Manager) to the device with the id';
  const isViewerAuthorized = userIsViewer && error?.description.indexOf(viewerRightsError) >= 0;

  let cartErrors = [];
  try {
    const internalError = get(error, 'internal_error');
    const hasError = !isEmpty(internalError);
    if (hasError) {
      const { err } = JSON.parse(internalError);
      cartErrors = err;
    }
  } catch (err) {
    cartErrors = [];
  }
  let productErrors = [];
  //Get error messages if products from e-commerce are not returned from API
  try {
    //
    if (!isEmpty(hasErrorFetchingSubscription && hasErrorFetchingSubscription.internal_error)) {
      const { data = {} } = JSON.parse(hasErrorFetchingSubscription?.internal_error);
      productErrors.push(data);
    }
  } catch (err) {
    productErrors = [];
  }

  const getErrorAndLoadingContent = () => {
    const freeMessageError = [
      {
        key: 'freeEcomServices',
        error:
          'myUplink premium services are currently available for testing purposes at no charge to the end user. This testing period is subject to change and may be terminated without prior notice.',
      },
    ];

    if (
      isLoadingSubscriptions ||
      (!isSubscriptionAvailable && !showSubscription && !isLoadingSubscriptions && !hasErrorFetchingSubscription)
    ) {
      return <Spinner dark />;
    }

    if (hasErrorFetchingSubscription) return <SubscriptionError errorData={hasErrorFetchingSubscription} />;

    if (!isPaidBrand) {
      return <ProductsError productErrors={freeMessageError} brandId={selectedSystem?.brandId} />;
    }

    if (!hasDeviceSubscription && !isEmpty(hasErrorFetchingSubscription)) {
      return <ProductsError productErrors={productErrors} brandId={selectedSystem?.brandId} />;
    }

    if (init && !loading && error && !isViewerAuthorized) {
      return <CartError cartErrors={cartErrors} error={error} />;
    }

    if (!showSubscription) {
      return (
        <>
          {isShopSupportedByUser && (
            <p className="Product__unsupportedCountry_description">
              <FormattedMessage
                id="shop.page.notSupportedCountryForUser"
                defaultMessage="Currently, we do not provide services of NIBE / CLM in your country."
              />
            </p>
          )}
          {isShopSupportedBySystem && (
            <p className="Product__unsupportedSystem_description">
              <FormattedMessage
                id="shop.page.notSupportedCountryForSystem"
                defaultMessage="The Store is not supported by the country of your system."
              />
            </p>
          )}
        </>
      );
    }

    /**
     * for NIBE USER :-
     * subscription valid until date should be in future then show add to cart otherwise show premium available message
     * for CLM USER :-
     * if subscription is on trial then show add to cart with trial message
     * if subscription is in active state and valid until is true then show cancel subscription page.
     * if subscription is in canceled state and valid until is true then show resume subscription page or if it is in grace period then show add to cart.
     * if subscription is in failed state and in grace period then show cancel subscription which then show add to cart page.
     */
    return userSubscriptions.map(({ subscription, variants, id, handle, title, description }) => {
      let remainingDays = 0;
      let formattedValidUntil;

      if (!isEmpty(subscription)) {
        const { validUntil } = subscription;
        remainingDays = moment.duration(moment(validUntil).diff(moment())).asDays();
        formattedValidUntil = new Date(validUntil).toLocaleDateString();
        remainingDays > 0
          ? localStorage.setItem('IS_SUBSCRIPTION_VALID', true)
          : localStorage.setItem('IS_SUBSCRIPTION_VALID', false);
      }
      const isPastValidUntil = moment(subscription?.validUntil) < moment.now();
      const isSubscriptionTrial = subscription?.status === SubscriptionStatus.TRIAL;
      const isSubscriptionFailed = subscription?.status === SubscriptionStatus.FAILED;
      const isSubscriptionActive = subscription?.status === SubscriptionStatus.ACTIVE;
      const isSubscriptionCanceled = subscription?.status === SubscriptionStatus.CANCELED;
      const isStripeSpecificBrand = isStripeBrand(stripeSpecificBrandList, selectedSystem?.brandId);

      const isSubscriptionNotAvailable =
        !subscription ||
        subscription === null ||
        isSubscriptionTrial ||
        ((!isStripeSpecificBrand || isSubscriptionCanceled) && isPastValidUntil);

      const isMonthlySubscriptionAvailable = !isStripeSpecificBrand && isSubscriptionActive && !isPastValidUntil;
      const isYearlySubscriptionAvailable =
        isStripeSpecificBrand &&
        (((isSubscriptionActive || isSubscriptionCanceled) && !isPastValidUntil) ||
          (isSubscriptionFailed && isPastValidUntil));

      let isProductAvailableInCart;
      if (!isEmpty(lineItems)) {
        lineItems.every(
          ({
            variant: {
              product: { id: cartVariantProductId },
            },
          }) => {
            isProductAvailableInCart = cartVariantProductId === id;
            return !isProductAvailableInCart;
          },
        );
      }

      return isShopAvailableForSystem || isShopAvailableForUser ? (
        <div className="Product__subscription-wrapper" key={id}>
          {isSubscriptionFailed && <SubscriptionFailureMessage />}
          <div className="Product__subscription-content-wrapper">
            <div className="Product__subscription-heading">
              <FormattedMessage id={'product.' + handle + '.title'} defaultMessage={title} />
            </div>
            {isMonthlySubscriptionAvailable && (
              <PremiumAvailable
                formattedValidUntil={formattedValidUntil}
                addToCart={addToCart}
                hasRightsToBuy={hasRightsToBuy && remainingDays < 30}
                variants={variants}
                disabled={isDemo || isProductAvailableInCart || loading}
              />
            )}
            {isSubscriptionNotAvailable && (
              <NoPremiumAvailable
                handle={handle}
                description={description}
                currencyCode={currencyCode}
                isSubscriptionTrial={isSubscriptionTrial}
                validUntil={subscription?.validUntil}
                hasRightsToBuy={hasRightsToBuy}
                disabled={isDemo || isProductAvailableInCart || loading}
                addToCart={addToCart}
                variants={variants}
              />
            )}
            {isYearlySubscriptionAvailable && (
              <YearlySubscription
                status={subscription?.status}
                validUntil={subscription?.validUntil}
                id={subscription?.id}
                isDemo={isDemo}
                isProductAvailableInCart={isProductAvailableInCart}
                currencyCode={currencyCode}
                price={variants?.[0]?.price}
                deviceId={selectedSystem.devices[0].id}
              />
            )}
          </div>
        </div>
      ) : (
        /* show if user don't have rights to buy subscription */
        remainingDays > 30 && hasPremiumFeature && (
          <div className="Product__subscription-content-wrapper Product__subscription-wrapper">
            <div className="Product__subscription-heading">
              <FormattedMessage id={'product.' + handle + '.title'} defaultMessage={title} />
            </div>
            <PremiumAvailable formattedValidUntil={formattedValidUntil} />
          </div>
        )
      );
    });
  };

  return (
    <div className="Product">
      <div className="Product__heading-wrapper">
        <Title
          className="ProductTitle"
          titleTranslationId="shop.page.subscriptionList"
          defaultMessage="Subscriptions"
        />
        {showSubscription && isSubscriptionAvailable && isPaidBrand && (
          <div className="Product__description">
            <FormattedMessage
              id="shop.page.product.description"
              defaultMessage="These subscriptions will extend the features you have access to on the myUplink web and mobile apps."
            />
          </div>
        )}
      </div>
      {getErrorAndLoadingContent()}
    </div>
  );
};

Products.propTypes = {
  addToCart: PropTypes.func.isRequired,
  cart: PropTypes.shape({
    init: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      key: PropTypes.string.isRequired,
      internal_error: PropTypes.shape({
        err: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            messages: PropTypes.string.isRequired,
          }),
        ),
      }),
      messages: PropTypes.arrayOf(string),
    }),
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        variant: PropTypes.shape({
          product: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
      }),
    ),
  }),
  products: PropTypes.shape({
    currencyCode: PropTypes.string.isRequired,
  }),
  userSubscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      subscription: PropTypes.shape({
        validUntil: PropTypes.string.isRequired,
      }),
      variant: PropTypes.shape({
        price: PropTypes.number.isRequired,
        length: PropTypes.number.isRequired,
      }),
      handle: PropTypes.bool.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      isFree: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  supportedCountries: PropTypes.shape({
    countries: PropTypes.arrayOf(string),
    loaded: PropTypes.bool,
  }),
  isDemo: PropTypes.bool.isRequired,
  hasRightsToBuy: PropTypes.bool,
  isShopAvailableForUser: PropTypes.bool,
  isShopAvailableForSystem: PropTypes.bool,
  isLoadingSubscriptions: PropTypes.bool,
  hasErrorFetchingSubscription: PropTypes.string,
  devices: PropTypes.shape({
    subscriptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }),
  selectedSystem: PropTypes.shape({
    brandId: PropTypes.string.isRequired,
  }),
  stripeSpecificBrandList: PropTypes.array,
  isPaidBrand: PropTypes.bool,
};

export default Products;
