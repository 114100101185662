import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './premiumFeatureTooltip.scss';

const PremiumFeatureTooltip = ({ premiumFeatureType, translationId, defaultMessage, style }) => (
  <div className="tooltip bs-tooltip-top premium-badge-tooltip" style={style}>
    <div className="arrow"></div>
    <div className="tooltip-inner">
      <FormattedMessage
        id={translationId ? translationId : `premiumFeatures.${premiumFeatureType}`}
        defaultMessage={defaultMessage || "You don't have permission to access this feature."}
      />
    </div>
  </div>
);

PremiumFeatureTooltip.propTypes = {
  premiumFeatureType: PropTypes.string,
  translationId: PropTypes.string,
  defaultMessage: PropTypes.string,
  style: PropTypes.object,
};
export default PremiumFeatureTooltip;
