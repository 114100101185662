import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import * as yup from 'yup';

import { Button, Input, Spinner } from 'jpi-cloud-web-ui-components';

import ItemDate from './ItemDate';

import { formatErrorMessage } from '../../../../localization/message-formatting';
import { useUserInfo } from '../../../../hooks';
import { DemoAccountVerifier } from '../../../layout/DemoAccountVerifier';

export const isSerialValid = serialNumber => {
  if (serialNumber?.length === 14 && /^\d{14}$/i.test(serialNumber)) return true;

  return /^[^\d\W]{2}\d{7}[^\d\W]{2}$/i.test(serialNumber);
};

const serialNumberSearchSchema = yup.object().shape({
  serialNumber: yup
    .string()
    .trim()
    .required('serial-number.error.validation.required')
    .test('formatAndlength', 'serial-number.format', isSerialValid),
});

const errorMessages = {
  'serial-number.format': {
    id: 'productRegistration.pro.serial.incorrect-format',
    defaultMessage: 'Product serial number format is incorrect.',
  },
  'serial-number.required': {
    id: 'serial-number.error.validation.required',
    defaultMessage: 'Serial number is required',
  },
};

const SerialNumberSearch = ({ intl, onSubmit, productList, locale, loading, unableToRegister }) => {
  const { isDemo } = useUserInfo();
  const initialValues = {
    serialNumber: '',
  };

  return (
    <div className="product-registration step-1">
      <div className="row">
        <div className="col-lg-12">
          {/*<p><FormattedMessage id="productRegistration.intro.1" defaultMessage="We are so confident in the quality and performance of our products that you can get a 5-year manufacturer's warranty for each heat pump. Registering your equipment is one of several requirements for obtaining the warranty. The manufacturer's warranty applies exclusively in compliance with the warranty conditions, professional commissioning and regular maintenance." /></p>*/}
          <p>
            <FormattedMessage
              id="productRegistration.intro.2"
              defaultMessage="On this page you will get an overview of all already registered products and you can register more. Only heat pumps can be registered as the main products of a system. Registration of accessories is not required."
            />
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <>
            <table className="table table-bordered registered-products">
              <thead className="thead-light">
                <tr>
                  <th scope="col">
                    <FormattedMessage id="productRegistration.serial-number" defaultMessage="Serial Number" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="productRegistration.installation-date" defaultMessage="Installation Date" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="productRegistration.warranty-end-date" defaultMessage="Warranty End Date" />
                  </th>
                </tr>
              </thead>
              {!productList.loading && (
                <tbody>
                  {productList.data.map(p => (
                    <tr key={p.serialNumber}>
                      <td>
                        <Link to={`/product-registration-info/${p.serialNumber}`}>{p.serialNumber}</Link>
                      </td>
                      <td>
                        <ItemDate timestamp={p.installationDate} locale={locale} />
                      </td>
                      <td>
                        <ItemDate timestamp={p.warrantyEndDate} locale={locale} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>

            {productList.loading && (
              <div className="registered-products-loading-container">
                <div>
                  <Spinner dark />
                </div>
              </div>
            )}
          </>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <h3>
            <FormattedMessage id="productRegistration.subtitle" defaultMessage="Register Your Product" />
          </h3>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <Formik initialValues={initialValues} validationSchema={serialNumberSearchSchema} onSubmit={onSubmit}>
            {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid }) => (
              <form onSubmit={handleSubmit} className="form--half">
                <FormattedMessage id="productRegistration.serialNumber">
                  {placeholder => (
                    <DemoAccountVerifier position="right" tooltipStyle={{ top: '40px', left: '105%' }}>
                      <Input
                        type="text"
                        name="serialNumber"
                        placeholder={placeholder}
                        value={values.serialNumber || ''}
                        error={
                          unableToRegister
                            ? intl.formatMessage({
                                id: 'productRegistration.findProductErrorMessage',
                                defaultMessage: 'Product registration is not possible for your product.',
                              })
                            : errors.serialNumber
                            ? formatErrorMessage(intl, errorMessages, errors.serialNumber)
                            : ''
                        }
                        onChange={handleChange}
                        disabled={isDemo || loading}
                      />
                    </DemoAccountVerifier>
                  )}
                </FormattedMessage>
                <div className="button-wrapper">
                  <DemoAccountVerifier position="right" tooltipStyle={{ left: '95%' }}>
                    <Button
                      type="submit"
                      className="button--secondary loader-button"
                      disabled={isDemo || !isValid || isSubmitting || loading}
                    >
                      <FormattedMessage id="productRegistration.findProduct" defaultMessage="Find Product" />
                      {loading && <Spinner dark />}
                    </Button>
                  </DemoAccountVerifier>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

SerialNumberSearch.propTypes = {
  intl: PropTypes.function,
  onSubmit: PropTypes.func.isRequired,
  productList: PropTypes.arrayOf(PropTypes.object),
  locale: PropTypes.string.isRequired,
  unableToRegister: PropTypes.bool,
  loading: PropTypes.bool,
};

export default injectIntl(SerialNumberSearch);
