import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { forgotOtp } from '../../../../../../api/accountMigration';

export function useForgotOtp(email) {
  const [otpSuccess, setOtpSuccess] = useState(undefined);
  const [otpError, setOtpError] = useState(undefined);

  const mutation = useMutation(() => forgotOtp(email), {
    onSuccess: () => {
      setOtpSuccess(true);
      setOtpError(undefined);
    },
    onError: () => {
      setOtpSuccess(undefined);
      setOtpError(true);
    },
  });

  return {
    otpSuccess,
    otpError,
    ...mutation,
  };
}
