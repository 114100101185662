import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';
import { connect } from 'react-redux';
import * as client from '../../../../../../Shop/client';
import PropTypes, { string } from 'prop-types';
import moment from 'moment';
import { isStripeSpecificBrand as isStripeBrand, redirectUserToPaymentWindow } from '../../../storeUtils';
import './updatecard.scss';
import { useUserInfo } from '../../../../../../../hooks';
import { DemoAccountVerifier } from '../../../../../../layout/DemoAccountVerifier';

const UpdateCard = ({
  cart,
  language,
  cardDetails,
  paymentProviders,
  paymentProviderSettings,
  getPaymentProviders,
  updatePaymentProviderSettings,
  cardURLDetails,
  selectedSystem,
  devices,
  paidBrands,
}) => {
  const { isDemo } = useUserInfo();
  const [{ id: deviceId }] = devices;
  const { savedCards = [] } = cardDetails;
  const [{ cardType = '', cardNoMask = '', cardExpDate = '' }] = savedCards;
  const { brandId = '' } = selectedSystem || {};
  const { stripeSpecificPaidBrands } = paidBrands;
  const isStripeSpecificBrand = isStripeBrand(stripeSpecificPaidBrands, brandId);

  const removeCard = async obj => {
    const { paymentProviderSettings: paymentProviderSettingsDetails = [] } = paymentProviderSettings;
    const [{ settings = '' }] = paymentProviderSettingsDetails;
    const parsedSettings = JSON.parse(settings);
    parsedSettings.savedCards = (settings.savedCards || []).filter(card => card.id !== obj.id);

    updatePaymentProviderSettings({
      ...paymentProviderSettings.paymentProviderSettings[0],
      settings: JSON.stringify(parsedSettings),
    });

    if (!settings.savedCards) {
      await getPaymentProviders(cart, deviceId, 'payment_settings');
    }
  };

  const updateCard = () => {
    const [{ internalName = '' } = {}] = paymentProviders;
    const { data = {} } = cardURLDetails;
    const { hostedPaymentPageUrl = '' } = data;
    const { languageTag = '' } = language;
    if (hostedPaymentPageUrl) {
      redirectUserToPaymentWindow(hostedPaymentPageUrl, languageTag, internalName);
    }
  };
  return (
    <div className="UpdateCard">
      <div className="UpdateCard__descriptionWrapper">
        <FormattedMessage
          id="paymentProviders.dibs-ticket.upcomingBilling"
          defaultMessage="The added credit{debit} card will be charged for upcoming billing."
          values={{
            debit: isStripeSpecificBrand ? '/debit' : '',
          }}
        />
        <br />
        <FormattedMessage
          id="paymentProviders.dibs-ticket.removeCancel"
          defaultMessage="If you remove the credit{debit} card your subscription will be canceled at the next billing."
          values={{
            debit: isStripeSpecificBrand ? '/debit' : '',
          }}
        />
      </div>
      <div className="UpdateCard__cardDetailsWrapper">
        <div className="UpdateCard__cardDetails">
          {cardType}&#58; {cardNoMask}
          <br />
          <FormattedMessage id="paymentProviders.dibs-ticket.cardExpire" defaultMessage="Valid until" />
          &#58; {moment.utc(cardExpDate).format('MM/YY')}
        </div>
        <div className="UpdateCard__buttonWrapper">
          <DemoAccountVerifier position="top">
            <Button className="UpdateCard__updateCard-button" onClick={() => updateCard()} disabled={isDemo}>
              <FormattedMessage id="paymentProviders.dibs-ticket.updateCard" defaultMessage="Update card" />
            </Button>
          </DemoAccountVerifier>
          <DemoAccountVerifier position="top">
            <Button className="UpdateCard__removeCard-button" onClick={removeCard} disabled={isDemo}>
              <FormattedMessage id="paymentProviders.dibs-ticket.removeCard" defaultMessage="Remove card" />
            </Button>
          </DemoAccountVerifier>
        </div>
      </div>
    </div>
  );
};

UpdateCard.propTypes = {
  cart: PropTypes.shape({
    init: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      key: PropTypes.string.isRequired,
      internal_error: PropTypes.shape({
        err: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            messages: PropTypes.string.isRequired,
          }),
        ),
      }),
      messages: PropTypes.arrayOf(string),
    }),
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        variant: PropTypes.shape({
          product: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
      }),
    ),
  }),
  language: PropTypes.shape({
    languageTag: PropTypes.string.isRequired,
  }),
  cardDetails: PropTypes.shape({
    savedCards: PropTypes.arrayOf(
      PropTypes.shape({
        cardExpDate: PropTypes.string.isRequired,
        cardNoMask: PropTypes.string.isRequired,
        cardType: PropTypes.string.isRequired,
      }),
    ),
  }),
  paymentProviders: PropTypes.arrayOf(
    PropTypes.shape({
      internalName: PropTypes.string.isRequired,
    }),
  ),
  paymentProviderSettings: PropTypes.shape({
    paymentProviderSettings: PropTypes.arrayOf(
      PropTypes.shape({
        settings: PropTypes.string.isRequired,
      }),
    ),
  }),
  cardURLDetails: PropTypes.shape({
    data: PropTypes.shape({
      hostedPaymentPageUrl: PropTypes.string.isRequired,
    }),
  }),
  getPaymentProviders: PropTypes.func.isRequired,
  updatePaymentProviderSettings: PropTypes.func.isRequired,
  selectedSystem: PropTypes.shape({
    brandId: PropTypes.string.isRequired,
  }),
  paidBrands: PropTypes.shape({
    stripeSpecificPaidBrands: PropTypes.array,
  }),
  devices: PropTypes.arrayOf({
    deviceId: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  cardURLDetails: state.shop.cardURLDetails,
  paymentProviders: state.shop.paymentProviders,
  paymentProviderSettings: state.shop.paymentProviderSettings,
  selectedSystem: state.app.selectedSystem,
  stripeSpecificPaidBrands: state.shop.stripeSpecificPaidBrands,
  devices: state.app.selectedSystem.devices,
  paidBrands: state.shop.paidBrands,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  updatePaymentProviderSettings(paymentProviderSettings) {
    dispatch(client.updatePaymentProviderSettings(paymentProviderSettings));
  },
  getPaymentProviders(cart, deviceId, activeTab) {
    dispatch(client.getPaymentProviders(cart, deviceId, activeTab));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCard);
