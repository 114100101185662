import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'jpi-cloud-web-ui-components';
import { FormattedMessage } from 'react-intl';
import DisconnectUserConfirmationModal from '../../SecurityTab/components/DisconnectUserConfirmationModal';
import { DemoAccountVerifier } from '../../../../../layout/DemoAccountVerifier';

const DisconnectUser = ({ system, showModal, toggleModal, disconnectUser, isDemo }) => (
  <div>
    <h3 className="remove-section">
      <div className="remove-section-message">
        <FormattedMessage
          id="system-profile.delete.disconnect-account.remove-message"
          defaultMessage="Disconnect account from system"
        />
        &nbsp;{system && system.name}
      </div>
    </h3>
    <DemoAccountVerifier position="right" tooltipStyle={{ left: '105%' }}>
      <Button className="button button--danger disconnect-button" type="button" disabled={isDemo} onClick={toggleModal}>
        <FormattedMessage id="devices.Disconnect" defaultMessage="Disconnect" />
      </Button>
    </DemoAccountVerifier>
    <DisconnectUserConfirmationModal toggleModal={toggleModal} showModal={showModal} disconnectUser={disconnectUser} />
  </div>
);

DisconnectUser.propTypes = {
  system: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  disconnectUser: PropTypes.func.isRequired,
  isDemo: PropTypes.bool,
};

export default DisconnectUser;
