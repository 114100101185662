import React, { useEffect, useState } from 'react';
import Modal from '../../../../layout/Modal';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import { formatErrorMessage } from '../../../../../localization/message-formatting';

import './passwordvalidationform.scss';

const errorMessages = {
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  unknown: {
    id: 'generic.error.request.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
  password_wrong: {
    id: 'profile.email.error.request.password.invalid',
    defaultMessage: 'Supplied password is not correct',
  },
  email_already_taken: {
    id: 'profile.email.error.request.email.taken',
    defaultMessage: 'The E-Mail address is already in use. Please try another E-Mail address',
  },
};

const PasswordValidationForm = ({ intl, onSubmit, onCancel, pswdError }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleSubmit = () => {
    if (!password) return setPasswordError('password.error.validation.required');

    setSubmitting(true);

    onSubmit(password);
  };

  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value);
    setPasswordError('');
    setSubmitting(false);
  };

  const handleCancel = () => {
    setPasswordError('');
    onCancel();
  };

  useEffect(() => {
    setPasswordError(pswdError);
    setSubmitting(false);
  }, [pswdError]);

  return (
    <Modal show={true}>
      <div className="passwordValidationForm">
        <div className="popup-modal">
          <div className="passwordValidationForm__wrapper">
            <p className="passwordValidationForm__title">
              <FormattedMessage
                id="profile.password.title"
                defaultMessage="To change your email address you must enter your password"
              />
            </p>
            <div className="passwordValidationForm__password-wrapper">
              <Input
                type="password"
                name="password"
                value={password}
                placeholder={intl.formatMessage({ id: 'label.currentPassword' })}
                error={passwordError && formatErrorMessage(intl, errorMessages, passwordError)}
                onChange={handlePasswordChange}
              />
            </div>

            <div className="passwordValidationForm__button-wrapper">
              <Button className="passwordValidationForm__button-cancel" onClick={handleCancel} disabled={isSubmitting}>
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="button"
                className="passwordValidationForm__button-save"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                <FormattedMessage id="button.save" defaultMessage="Save" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

PasswordValidationForm.propTypes = {
  intl: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  pswdError: PropTypes.string,
};

export default injectIntl(PasswordValidationForm);
