import { validateOldUserCredentials } from '.';
import { API } from './client';

export const cancelMigrationNotification = async () => {
  const response = API.delete('/v2/users/profile/migration-notification');
  return response.data;
};

export const validateUser = async ({ password, email }) => {
  const userValidationResponse = await validateOldUserCredentials(email, password);
  return userValidationResponse.data.id;
};

export const migrateUser = async ({ oldUserId }) => {
  const migrationResponse = await API.put(`/v2/old-users/migrate/merge?&oldUserId=${oldUserId}`);
  return migrationResponse.data;
};

export const forgotOtp = async email => {
  const response = await API.post('/v2/old-users/forgot-password/otp', { email });
  return response;
};

export const verifyOtp = async (email, otp) => {
  const response = await API.put('v2/old-users/forgot-password/verify-otp', { email, otp });
  return response;
};

export default {
  cancelMigrationNotification,
  migrateUser,
  forgotOtp,
  verifyOtp,
};
