import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import Dropdown from '../../../../../inputs/Dropdown';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { USER_SECURITY_LEVELS, securityLevels, securityLevelsPriorities, systemRoles } from '../costants';
import './usersList.scss';
import { DemoAccountVerifier } from '../../../../../layout/DemoAccountVerifier';

class UsersList extends React.Component {
  static propTypes = {
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    email: PropTypes.string.isRequired,
    toggleModal: PropTypes.func.isRequired,
    changeSecurityLevel: PropTypes.func.isRequired,
    userIsAdmin: PropTypes.bool.isRequired,
    isDemo: PropTypes.bool,
    intl: PropTypes.object.isRequired,
  };

  state = {
    sortParameter: null,
    updatedUserId: null,
  };

  setSortingParameter = sortParameter => {
    let isAscending;

    if (this.state.sortParameter) {
      isAscending = sortParameter === this.state.sortParameter.parameter ? !this.state.sortParameter.isAscending : true;
    } else {
      isAscending = true;
    }

    this.setState({ ...this.state, sortParameter: { parameter: sortParameter, isAscending: isAscending } });
  };

  sortArray(array, parameter, isAscending) {
    if (parameter === 'role')
      return isAscending
        ? array.sort((a, b) =>
            securityLevelsPriorities[a[parameter]] > securityLevelsPriorities[b[parameter]] ? 1 : -1,
          )
        : array.sort((a, b) =>
            securityLevelsPriorities[a[parameter]] < securityLevelsPriorities[b[parameter]] ? 1 : -1,
          );
    return isAscending
      ? array.sort((a, b) => (a[parameter] > b[parameter] ? 1 : -1))
      : array.sort((a, b) => (a[parameter] < b[parameter] ? 1 : -1));
  }

  confirmUserDisconnect = (user, isDemo) => {
    return !isDemo && this.props.toggleModal(user);
  };

  handleRemoveUser = (user, adminUsersCount, isDemo) => {
    if (user.role === USER_SECURITY_LEVELS['admin'] && adminUsersCount === 1) {
      return;
    }
    this.confirmUserDisconnect(user, isDemo);
  };

  usersCount = userLevel => this.props.users.filter(user => user.role === USER_SECURITY_LEVELS[userLevel]).length;

  getSystemRole = role => {
    const systemRoleText = systemRoles.find(i => i.value.toLocaleLowerCase() === role.toLocaleLowerCase())?.text;
    return systemRoleText ? this.props.intl.formatMessage(systemRoleText) : role;
  };

  render() {
    const { users, email, changeSecurityLevel, userIsAdmin, isDemo, intl } = this.props;
    const { sortParameter } = this.state;
    const adminUsersCount = this.usersCount('admin');
    const viewerUsersCount = this.usersCount('viewer');
    const managerUsersCount = this.usersCount('manager');

    var sortedUsers = sortParameter
      ? this.sortArray([...users], sortParameter.parameter, sortParameter.isAscending)
      : users;

    const removeUserIconClassName = classNames({
      'fa-stack remove-user': true,
      disabled: isDemo,
    });

    return (
      <div className="list">
        <div className="list-item sort-header">
          <div className="name-email-head">
            <div className="column user-name">
              <button onClick={() => this.setSortingParameter('name')}>
                <span>
                  <FormattedMessage id="security.Name" defaultMessage="Name" /> <FontAwesomeIcon icon={faSort} />
                </span>
              </button>
            </div>
            <div className="column user-email">
              <button onClick={() => this.setSortingParameter('email')}>
                <span>
                  <FormattedMessage id="security.Email" defaultMessage="E-mail" /> <FontAwesomeIcon icon={faSort} />
                </span>
              </button>
            </div>
          </div>
          <div className="column user-securityLevel security-head">
            <button onClick={() => this.setSortingParameter('role')}>
              <span>
                <FormattedMessage id="security.SecurityLevel" defaultMessage="Security Level" />{' '}
                <FontAwesomeIcon icon={faSort} />
              </span>
            </button>
          </div>
          <div className="column user-actions"></div>
        </div>
        {sortedUsers.length > 0 ? (
          sortedUsers.map(user => (
            <div key={user.id} className="list-item">
              <div className="name-email-wrap">
                <div className="column user-name">{user.name}</div>
                <div className="column user-email" title={user.email}>
                  {user.email}
                </div>
              </div>
              <div className="security-action-wrap">
                <div className="security-level-dropdown column user-securityLevel">
                  {userIsAdmin && user.email !== email && securityLevels.some(sl => sl.value === user.role) ? (
                    <DemoAccountVerifier position="top" tooltipStyle={{ left: '50px' }} style={{ width: '100%' }}>
                      <Dropdown
                        id={user.name}
                        selectedItem={securityLevels.find(sl => sl.value === user.role)}
                        items={securityLevels}
                        labelGetter={e => intl.formatMessage(e.text)}
                        onSelect={i => {
                          changeSecurityLevel(user, i.value);
                        }}
                        disabled={
                          isDemo || (adminUsersCount === 1 && managerUsersCount === 0 && viewerUsersCount === 0)
                        }
                      />
                    </DemoAccountVerifier>
                  ) : (
                    <span className="readonly-securityLevel">{this.getSystemRole(user.role)}</span>
                  )}
                </div>
                <div className="column user-actions">
                  {userIsAdmin && user.email !== email && (
                    <DemoAccountVerifier position="top" tooltipStyle={{ right: '-80px', left: 'unset' }}>
                      <div
                        className="text-danger"
                        onClick={() => {
                          if (isDemo) return;
                          this.handleRemoveUser(user, adminUsersCount, isDemo);
                        }}
                      >
                        <span className={removeUserIconClassName}>
                          <i className="fa fa-stack-2x fa-times"></i>
                        </span>
                      </div>
                    </DemoAccountVerifier>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <h3 align="center">
            <FormattedMessage id="security.EmptyList" defaultMessage="Users list is empty" />
          </h3>
        )}
      </div>
    );
  }
}

export default injectIntl(UsersList);
