import React from 'react';

import { FormattedMessage } from 'react-intl';

import EmailSection from './components/EmailSection';
import PasswordSection from './components/PasswordSection';
import MFAuthenticatorSection from './components/MFAuthenticatorSection';

import { isAzureB2CEnabled } from '../../../../constants/constants';

import './account-security.scss';

const AccountSecurityTab = () => {
  // Added flag within this task https://nibejpi.atlassian.net/browse/CLOUDTEAM-16154
  // Remove this flag later;
  const B2CFeatureFlag = false;

  return (
    <div className="accSecurity_wrapper">
      <h1>
        <FormattedMessage id="label.account-security" defaultMessage="Account Security" />
      </h1>

      <EmailSection />
      <hr />
      <br />

      <PasswordSection />
      <hr />
      <br />
      {isAzureB2CEnabled && B2CFeatureFlag && (
        <>
          <MFAuthenticatorSection />
          <hr />
        </>
      )}
    </div>
  );
};

export default AccountSecurityTab;
