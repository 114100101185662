import React, { Component } from 'react';

import { push } from 'connected-react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ModalBody } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';

import { Spinner, Title } from 'jpi-cloud-web-ui-components';

import Modal from '../../layout/Modal';

import RegistrationForm from './components/RegistrationForm/RegistrationForm';
import SerialNumberSearch, { isSerialValid } from './components/SerialNumberSearch';
import RegistrationSuccess from './components/RegistrationSuccess';

import {
  findProduct,
  getRegisteredProducts,
  getYourTechnicianByNameHint,
  getYourTechniciansList,
  registerProduct,
  cancelProductRegistration,
} from './actions';

import { BRANDS_WITH_HIDDEN_PRODUCT_REGISTRATION } from '../../constants/constants';

import './product-registration.scss';

class ProductRegistration extends Component {
  state = {
    values: {
      serialNumber: '',
      productName: '',
      name: '',
      email: '',
      phone: '',
      installationDate: moment().format('YYYY-MM-DD'),
      registrationDate: moment().format('YYYY-MM-DD'),
      warrantyEndDate: moment().add(5, 'years').format('YYYY-MM-DD'),
      installerName: '',
      operatingHours: '0',
      address1: '',
      address2: '',
      postalCode: '',
      city: '',
      region: '',
      country: '',
      warrantyConditions: false,
      dataConsent: false,
    },

    serialNumberVerification: false,
    showWarrantyInfoModal: false,
    warrantyInfoTranslationId: '',
    loadingData: false,
    showForm: false,
  };

  get initialValues() {
    return {
      serialNumber: '',
      productName: '',
      name: '',
      email: '',
      phone: '',
      installationDate: moment().format('YYYY-MM-DD'),
      registrationDate: moment().format('YYYY-MM-DD'),
      warrantyEndDate: moment().add(5, 'years').format('YYYY-MM-DD'),
      installerName: '',
      operatingHours: '0',
      address1: '',
      address2: '',
      postalCode: '',
      city: '',
      region: '',
      country: '',
      warrantyConditions: false,
      dataConsent: false,
    };
  }

  handleIncompleteProfile = () => {
    NotificationManager.error(
      <>
        <FormattedMessage
          id="product-registration.error.profile-incomplete.description"
          defaultMessage="Please complete your profile data to proceed with registration."
        />
        <br />
        <Link className="page-link" to="/profile-settings">
          <FormattedMessage id="login.forgot-password-link" defaultMessage="Click here" />
        </Link>
      </>,
      <FormattedMessage
        id="product-registration.error.profile-incomplete.title"
        defaultMessage="Product registration error"
      />,
      10000,
    );

    this.props.cancelProductRegistration();
  };

  async componentDidMount() {
    const { userInfo, selectedSystem, goToPage } = this.props;

    const brandIdLower = selectedSystem?.brandId?.toLowerCase();
    const isHiddenBrand = BRANDS_WITH_HIDDEN_PRODUCT_REGISTRATION.includes(brandIdLower);
    const userCountryName = userInfo?.address?.country?.name;

    if (isHiddenBrand || (userCountryName !== 'Germany' && userInfo?.isDemo)) {
      return goToPage('/');
    }

    await this.loadData();
  }

  componentDidUpdate() {
    const { serial: serialNumber } = this.props.match.params;

    if (!serialNumber && this.state.showForm) {
      this.setState({ showForm: false });
    }
  }

  loadData = async () => {
    const { match, userInfo, getRegisteredProducts, getYourTechniciansList } = this.props;

    this.setState({ loadingData: true });

    await getRegisteredProducts(userInfo.id);
    await getYourTechniciansList();

    const { serial: serialNumber } = match.params;

    if (serialNumber) {
      await this.verifySerialNumber(serialNumber);
    }

    this.setState({ loadingData: false });
  };

  verifySerialNumber = async serial => {
    this.setState({ serialNumberVerification: true });

    if (!isSerialValid(serial)) {
      NotificationManager.error(
        <FormattedMessage
          id="productRegistration.pro.serial.incorrect-format"
          defaultMessage="Product serial number format is incorrect."
        />,
      );
      return this.setState({ serialNumberVerification: false });
    }

    const isValid = await this.props.findProduct(serial);

    this.setState({ serialNumberVerification: false });

    if (isValid) {
      if (!this.props.userInfo.fullName) return this.handleIncompleteProfile();

      this.prepareValues(serial);
      this.setState({ showForm: true });
      return;
    }

    if (this.props.match?.params?.serial) {
      NotificationManager.error(
        <FormattedMessage
          id="productRegistration.pro.register.invalid-serial"
          defaultMessage="The serial number from your request may be invalid or incorrect, please verify and try again. Thank you!"
        />,
        null,
        5000,
      );
      this.props.cancelProductRegistration();
    }

    this.props.goToPage('/product-registration');
  };

  prepareValues = serialNumber => {
    const { userInfo, countries } = this.props;

    const userInfoData = userInfo
      ? {
          name: userInfo.fullName.trim(),
          email: userInfo.email.trim(),
          address1: userInfo.address.lineOne.trim(),
          address2: (userInfo.address.lineTwo && userInfo.address.lineTwo.trim()) || '',
          postalCode: userInfo.address.postalCode.trim(),
          city: userInfo.address.city.trim(),
          region: (userInfo.address.region && userInfo.address.region.trim()) || '',
          country: countries.find(_ => _.name === 'Germany'), //userInfo.address.country,
        }
      : {};

    this.setState({ values: { ...this.state.values, ...userInfoData, serialNumber } });

    this.props.goToPage(`/product-registration/${serialNumber}`);
  };

  onSubmitSerialNumberSearch = async (values, { setSubmitting }) => {
    setSubmitting(true);
    this.props.cancelProductRegistration();

    const serialNumber = values.serialNumber.trim();

    await this.verifySerialNumber(serialNumber);

    setSubmitting(false);
  };

  onSubmitRegistrationForm = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const newValues = {
      ...this.state.values,
      warrantyEndDate: moment(values.installationDate).add(5, 'years').format('YYYY-MM-DD'),
      productName: values.productName.trim(),
      phone: values.phone.trim(),
      operatingHours: values.operatingHours.trim(),
      installerName: values.installerName.trim(),
      address1: values.address1.trim(),
      address2: values.address2.trim(),
      city: values.city.trim(),
      country: values.country,
      postalCode: values.postalCode.trim(),
      region: values.region.trim(),
      installationDate: moment(values.installationDate).format('YYYY-MM-DD'),
    };

    this.setState({ ...this.state, values: newValues });

    try {
      await this.props.registerProduct(newValues, this.props.userInfo);

      this.setState({ showForm: false, values: this.initialValues });
    } catch (error) {
      if (error?.response?.status === 400) {
        this.setState({ showForm: true });
        const { internal_error: errDescription } = error?.response?.data || {};

        if (errDescription === 'Invalid Address')
          return NotificationManager.error(
            <FormattedMessage
              id="product-registration.form.error.address"
              defaultMessage="Unable to register product: Address is not valid."
            />,
          );
      }

      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );
    } finally {
      setSubmitting(false);
    }
  };

  toggleWarrantyInfoModal = warrantyInfoTranslationId => {
    this.setState({
      ...this.state,
      showWarrantyInfoModal: true,
      warrantyInfoTranslationId,
    });
  };

  hideWarrantyInfoModal = () => {
    this.setState({
      ...this.state,
      showWarrantyInfoModal: false,
    });
  };

  handleCancel = () => {
    this.props.cancelProductRegistration();

    this.props.goToPage('/product-registration');

    this.setState({ showForm: false, serialNumber: '' });
  };

  handleSuccess = async () => {
    const { getRegisteredProducts, goToPage, userInfo } = this.props;

    await getRegisteredProducts(userInfo.id);

    this.setState({ values: { ...this.state.values, serialNumber: '', showForm: false } });

    goToPage('/product-registration');
  };

  render() {
    if (!this.props.userInfo) return <Spinner dark />;

    return (
      <div className="page-content">
        <div className="row">
          <div className="col-lg-12">
            {this.props.productFound?.canBeRegistered && !this.props.registrationSucceeded ? (
              <Title titleTranslationId="productRegistration.subtitle" defaultMessage="Register Your Product" />
            ) : (
              <Title titleTranslationId="productRegistration.title" defaultMessage="Product Registration" />
            )}
          </div>
        </div>

        {this.state.loadingData ? (
          <Spinner dark />
        ) : (
          !this.state.showForm &&
          !this.props.registrationSucceeded && (
            <SerialNumberSearch
              productList={this.props.productList}
              locale={this.props.language.selectedLanguage}
              onSubmit={this.onSubmitSerialNumberSearch}
              loading={this.state.serialNumberVerification}
              unableToRegister={
                this.props.productFound &&
                !this.props.productFound.canBeRegistered &&
                this.props.serialNumberSearchSubmitted
              }
            />
          )
        )}

        {this.state.showForm && this.props.match.params.serial && (
          <RegistrationForm
            countries={this.props.countries}
            initialValues={this.state.values}
            yourTechnician={this.props.yourTechnician.data}
            yourTechnicianNames={this.props.yourTechnicianNames}
            onSubmit={this.onSubmitRegistrationForm}
            toggleWarrantyInfoModal={this.toggleWarrantyInfoModal}
            getYourTechnicianByNameHint={this.props.getYourTechnicianByNameHint}
            onCancel={this.handleCancel}
          />
        )}

        {this.state.showWarrantyInfoModal && this.state.warrantyInfoTranslationId && (
          <Modal show={this.state.showWarrantyInfoModal} backdrop={true} onHide={() => this.hideWarrantyInfoModal()}>
            <ModalBody>
              <FormattedHTMLMessage id={this.state.warrantyInfoTranslationId} />
            </ModalBody>
          </Modal>
        )}

        {this.props.registrationFormSubmitted && !this.props.registrationSucceeded && (
          <div className="product-registration step-2 text-danger row">
            <div className="col-lg-12">
              <h3>
                <FormattedMessage id="productRegistration.error" defaultMessage="Error" />
              </h3>
              <p>
                <FormattedMessage
                  id="productRegistration.errorMessage"
                  defaultMessage="The product can't be registered."
                />
              </p>
            </div>
          </div>
        )}

        {this.props.registrationFormSubmitted && this.props.registrationSucceeded && (
          <RegistrationSuccess onProceed={this.handleSuccess} />
        )}
      </div>
    );
  }
}

ProductRegistration.propTypes = {
  userInfo: PropTypes.object,
  language: PropTypes.object.isRequired,
  productList: PropTypes.arrayOf(PropTypes.object),
  productFound: PropTypes.object,
  registrationSucceeded: PropTypes.bool,
  serialNumberSearchSubmitted: PropTypes.bool,
  registrationFormSubmitted: PropTypes.bool,
  getRegisteredProducts: PropTypes.func.isRequired,
  findProduct: PropTypes.func.isRequired,
  registerProduct: PropTypes.func.isRequired,
  cancelProductRegistration: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  getYourTechniciansList: PropTypes.func.isRequired,
  yourTechnician: PropTypes.object.isRequired,
  getYourTechnicianByNameHint: PropTypes.func.isRequired,
  yourTechnicianNames: PropTypes.array.isRequired,
  selectedSystem: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = ({ app: { userInfo, countries, selectedSystem }, language, productRegistration }) => ({
  userInfo,
  countries,
  language,
  selectedSystem,
  ...productRegistration,
});

const mapDispatchToProps = {
  findProduct,
  registerProduct,
  cancelProductRegistration,
  getRegisteredProducts,
  getYourTechniciansList,
  getYourTechnicianByNameHint,
  goToPage: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductRegistration);
