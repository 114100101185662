import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Spinner } from 'jpi-cloud-web-ui-components';

import NumberInput from '../../inputs/NumberInput';
import OptionInput from '../../inputs/OptionInput';
import Select from '../../inputs/Select';
import Slider from '../../inputs/Slider';
import TextBox from '../../inputs/TextBox';
import ToggleSwitch from '../../inputs/ToggleSwitch';
import DisplayParameter from '../DisplayParameter';

import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';
import TimePicker from '../../pages/DeviceScheduling/components/TimePicker';

import { cleanTileSettings, getTileSettings } from './actions';

import { EL_PRICE_PARAM_ID } from '../../constants/constants';
import { USER_SECURITY_LEVELS } from '../../pages/SystemProfile/components/SecurityTab/costants';

import './tile-settings.scss';
import { DemoAccountVerifier } from '../../layout/DemoAccountVerifier';

export const SettingPresentationTypes = {
  option: 'option',
  slider: 'slider',
  plusminus: 'plusminus',
  toggle: 'toggle',
  display: 'display',
  dropdown: 'dropdown',
  textbox: 'textbox',
  time: 'time',
};

class TileSettings extends React.Component {
  constructor() {
    super();

    this.timeout = null;
    this.interval = null;
  }

  async componentDidMount() {
    await this.updateTileSettings();

    this.triggerRefresh();
  }

  componentWillUnmount() {
    this.props.cleanTileSettings();

    clearInterval(this.interval);
    clearTimeout(this.timeout);
  }

  async updateTileSettings({ silent } = {}) {
    const { settingParameters, getTileSettings } = this.props;
    if (!settingParameters?.length) return;

    const [firstParam = {}] = settingParameters;

    await getTileSettings({
      deviceId: firstParam.deviceId,
      parameterIds: settingParameters.map(({ parameter }) => parameter.id),
      silent,
    });
  }

  handleSettingUpdate =
    ({ setting, isTime = false }) =>
    value => {
      const settingValue = isTime ? moment(value, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds') : value;
      this.props.onChangeSetting(setting.deviceId, setting.parameter.id, setting.parameter.unit, settingValue);
      this.triggerRefresh({ fast: true });
    };

  triggerRefresh = ({ fast = false } = {}) => {
    if (fast) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.updateTileSettings({ silent: true });
      }, 3000);
    }

    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.updateTileSettings({ silent: true });
    }, 10000);
  };

  // TODO: Move this to component
  renderNecessaryControl = (setting = {}, manageEnabled, userIsViewer, systemStatus, isDemoUser) => {
    const { presentation, enumValues = [], defaultVal = 0 } = setting.parameter;
    const settingValue = this.props.settingValues.find(
      ({ parameterId }) => parseInt(parameterId) === parseInt(setting.parameter?.id),
    );

    const selectedValue = settingValue?.pendingValue || (settingValue?.value ?? defaultVal);

    // TODO: Refactor
    const disableTileSettingUpdate = () => {
      if (isDemoUser) return true; //if user has the right to view Dashboard, enable changing tile settings
      if (this.props.isDashboardFree) return false; //if user has the right to view Dashboard, enable changing tile settings
      if (!manageEnabled) return true; //if manageEnabled is false, disable changing tile settings
      if (userIsViewer) return true; //if user has view only rights, disbale changing tile settings

      return false;
    };

    let ParamControl;
    switch (presentation) {
      case SettingPresentationTypes.display:
        ParamControl = <DisplayParameter parameter={setting} />;
        break;
      case SettingPresentationTypes.option:
        ParamControl = (
          <DemoAccountVerifier position="top">
            <OptionInput
              value={selectedValue}
              pendingValue={settingValue?.pendingValue || null}
              brandId={this.props.brandId}
              disabled={isDemoUser || !manageEnabled || userIsViewer}
              disabledDueToOffline={!isDemoUser && systemStatus.currentStatus !== 'online'}
              enumValues={enumValues}
              premiumFeatureType={isDemoUser ? null : userIsViewer ? 'permissionAccess' : 'manage'}
              onChange={this.handleSettingUpdate({ setting })}
            />
          </DemoAccountVerifier>
        );
        break;
      case SettingPresentationTypes.slider:
        ParamControl = (
          <Slider
            value={selectedValue}
            pendingValue={settingValue?.pendingValue || null}
            scaleVal={setting.parameter.scaleVal}
            valuesOverride={setting.parameter.enumValues}
            unit={setting.parameter.unit}
            isDisabledAndVisibleTooltip={disableTileSettingUpdate()}
            disabledDueToOffline={!isDemoUser && systemStatus.currentStatus !== 'online'}
            onChange={this.handleSettingUpdate({ setting })}
            stepVal={setting.parameter.stepValScaled || setting.parameter.stepVal || 1}
            minVal={setting.parameter.minVal}
            maxVal={setting.parameter.maxVal}
            premiumFeatureType={isDemoUser ? null : userIsViewer ? 'permissionAccess' : 'manage'}
          />
        );
        break;
      case SettingPresentationTypes.plusminus:
        ParamControl = (
          <NumberInput
            value={selectedValue}
            unit={setting.parameter.unit}
            pendingValue={settingValue?.pendingValue || null}
            replaceValues={setting.parameter.enumValues.map(ev => ({
              ...ev,
              value: ev.value * setting.parameter.scaleVal,
            }))}
            isDisabledAndVisibleTooltip={isDemoUser || !manageEnabled || userIsViewer}
            disabledDueToOffline={!isDemoUser && systemStatus.currentStatus !== 'online'}
            onChange={this.handleSettingUpdate({ setting })}
            stepVal={setting.parameter.stepValScaled || setting.parameter.stepVal || 1}
            minVal={setting.parameter.minVal}
            maxVal={setting.parameter.maxVal}
            premiumFeatureType={isDemoUser ? null : userIsViewer ? 'permissionAccess' : 'manage'}
            valueFormatter={v => (setting.parameter.decimals ? (+v).toFixed(setting.parameter.decimals) : v)}
          />
        );
        break;
      case SettingPresentationTypes.toggle:
        ParamControl = (
          <ToggleSwitch
            isDisabledAndVisibleTooltip={isDemoUser || !manageEnabled || userIsViewer}
            disabledDueToOffline={!isDemoUser && systemStatus.currentStatus !== 'online'}
            isChecked={Number(selectedValue) === 1}
            onChange={this.handleSettingUpdate({ setting })}
            textOff={enumValues[0]?.text}
            textOn={enumValues[1]?.text}
            premiumFeatureType={isDemoUser ? null : userIsViewer ? 'permissionAccess' : 'manage'}
            pendingValue={settingValue?.pendingValue}
            label={setting.parameter.name}
          />
        );
        break;
      case SettingPresentationTypes.dropdown:
        ParamControl = (
          <Select
            id={this.props.brandId}
            className="dropdown"
            options={enumValues}
            value={selectedValue}
            pendingValue={settingValue?.pendingValue || null}
            isDisabledAndVisibleTooltip={isDemoUser || !manageEnabled || userIsViewer}
            disabledDueToOffline={!isDemoUser && systemStatus.currentStatus !== 'online'}
            onValueChange={this.handleSettingUpdate({ setting })}
            renderOptions={({ value, text }) => (
              <option value={value} key={value}>
                {text}
              </option>
            )}
          />
        );
        break;
      case SettingPresentationTypes.textbox:
        // NOTE: Potentially unused option
        ParamControl = (
          <TextBox
            value={selectedValue}
            maxLength={setting.parameter.maxLen}
            type={setting.parameter.textInput}
            typeRegex={setting.parameter.textRegex}
            textHelper={setting.parameter.textHelper}
            premiumFeatureType={isDemoUser ? null : userIsViewer ? 'permissionAccess' : 'manage'}
            isDisabledAndVisibleTooltip={isDemoUser || !manageEnabled || userIsViewer}
            disabledDueToOffline={!isDemoUser && systemStatus.currentStatus !== 'online'}
            onTextBoxValueChange={this.handleSettingUpdate({ setting })}
          />
        );
        break;
      case SettingPresentationTypes.time: {
        // NOTE: Potentially unused option
        const milliseconds = 1000;
        const format = setting.value % 60 === 0 ? 'HH:mm' : 'HH:mm:ss';
        const time = moment.utc(selectedValue * milliseconds).format(format);
        const step = setting.parameter.stepVal / 60;

        const isTimepickerDisabled = !manageEnabled || userIsViewer || systemStatus.currentStatus !== 'online';

        ParamControl = (
          <div className="timePicker">
            {setting.parameter.isWritable ? (
              <DemoAccountVerifier position="top">
                <TimePicker
                  time={time}
                  onTimeChange={this.handleSettingUpdate({ setting, isTime: true })}
                  disabled={isDemoUser || isTimepickerDisabled}
                  step={step}
                />
              </DemoAccountVerifier>
            ) : (
              <div className="displaytime">{time}</div>
            )}
          </div>
        );
        break;
      }
    }

    return (
      <>
        <div className="param-control">{ParamControl}</div>

        <div className="settingName">
          {setting.parameter?.presentation !== SettingPresentationTypes.display &&
            settingValue?.pendingValue !== undefined &&
            settingValue?.pendingValue !== null && <Spinner />}
          <p>{setting.parameter.name}</p>
        </div>
      </>
    );
  };

  render() {
    const { settingParameters, loading, settingValues, manageEnabled, selectedSystem, systemStatus, isDemoUser } =
      this.props;
    const userIsViewer = selectedSystem && selectedSystem.userRole === USER_SECURITY_LEVELS.viewer;

    return (
      <div className="setting-parameter-wrapper">
        {loading || !settingValues ? (
          <Spinner />
        ) : (
          settingParameters
            .filter(s => s.parameter?.presentation)
            .map(setting => {
              const isElectricalPrice = setting.id === EL_PRICE_PARAM_ID;
              return (
                <div
                  className={`tileSetting ${
                    (manageEnabled && !userIsViewer) || this.props.isDashboardFree ? '' : 'tileSetting--disabled'
                  }`}
                  key={setting.parameter.id}
                >
                  {' '}
                  {!isElectricalPrice && (
                    <div className="settingControl">
                      {setting.parameter.presentation !== SettingPresentationTypes.display && !manageEnabled && (
                        <>
                          <div className="badge badge-success premium-badge tile-badge">PREMIUM</div>
                          {setting.parameter.presentation !== SettingPresentationTypes.display &&
                            !this.props.isDashboardFree && (
                              <PremiumFeatureTooltip
                                premiumFeatureType={userIsViewer ? 'permissionAccess' : 'manage'}
                              />
                            )}
                        </>
                      )}
                      {this.renderNecessaryControl(setting, manageEnabled, userIsViewer, systemStatus, isDemoUser)}
                    </div>
                  )}
                </div>
              );
            })
        )}
      </div>
    );
  }
}

TileSettings.propTypes = {
  settingParameters: PropTypes.array,
  brandId: PropTypes.string.isRequired,
  getTileSettings: PropTypes.func.isRequired,
  onChangeSetting: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  manageEnabled: PropTypes.bool,
  settingValues: PropTypes.array,
  cleanTileSettings: PropTypes.func.isRequired,
  selectedSystem: PropTypes.object,
  systemStatus: PropTypes.object,
  isDashboardFree: PropTypes.bool,
  isDemoUser: PropTypes.bool,
};

export default connect(
  ({
    tileSettings,
    features: { premiumFeatures, isDashboardFree },
    app: { selectedSystem, userInfo },
    systemStatus,
  }) => ({
    ...tileSettings,
    manageEnabled: premiumFeatures?.manage,
    selectedSystem,
    systemStatus,
    isDashboardFree,
    isDemoUser: userInfo.isDemo,
  }),
  {
    getTileSettings,
    cleanTileSettings,
  },
)(TileSettings);
