import { Checkbox, Spinner } from 'jpi-cloud-web-ui-components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ToggleSwitch from '../../../../../inputs/ToggleSwitch';
import { email } from '../../../constants';
import {
  onChangeActiveTab,
  updateSystemForEmailNotifications,
  updateSystemForPushNotifications,
  updateUserProfile,
} from '../actions';

import '../notificationsettingstab.scss';
import { DemoAccountVerifier } from '../../../../../layout/DemoAccountVerifier';

const systemCells = [
  {
    id: 'group.systems-list.system',
    defaultMessage: 'System',
  },
  {
    id: 'group.systems-list.select',
    defaultMessage: 'Select',
    lastCell: true,
  },
];

const SystemRow = ({ system, onNotifyByEmailChange, onNotifyByPushChange, activeTab, disabled }) => {
  return (
    <div className="systemList-item">
      <div className="systemList-item--systemNameAndNumber">
        <div className="systemList-item--systemNameAndNumber__name">{system.name}&nbsp;</div>
        <div className="systemList-item--systemNameAndNumber__serialNumber">&#45;&nbsp;{system.serialNumber}</div>
      </div>
      <div className="systemList-item--value-last">
        <DemoAccountVerifier position="top" tooltipStyle={{ left: '-80px', bottom: '110%' }}>
          <Checkbox
            id={`${system.id} ${system.name}`}
            name={system.name}
            checked={activeTab === email ? system.notifyByEmail : system.notifyByPush}
            onChange={e =>
              activeTab === email
                ? onNotifyByEmailChange(system, e.target.checked)
                : onNotifyByPushChange(system, e.target.checked)
            }
            disabled={disabled}
          />
        </DemoAccountVerifier>
      </div>
    </div>
  );
};

SystemRow.propTypes = {
  system: PropTypes.object.isRequired,
  onNotifyByEmailChange: PropTypes.func.isRequired,
  onNotifyByPushChange: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const SystemHeaderCell = ({ id, defaultMessage, lastCell }) => {
  return (
    <div className={lastCell ? 'systemList-item--value-last' : 'systemList-item--value'}>
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </div>
  );
};

SystemHeaderCell.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  lastCell: PropTypes.bool,
};

const NotificationToggle = ({ onChangeToggle, isSystemUpdating, isChecked, isDemoUser }) => {
  return (
    <div className="systemList-item">
      <div className="systemList-item--toggleText">
        <FormattedMessage id="notification.message.system-toggle" defaultMessage="Disable all notifications" />
      </div>
      <div className="systemList-item--value-last">
        <ToggleSwitch
          isChecked={isChecked}
          onChange={() => onChangeToggle()}
          isDisabledAndVisibleTooltip={isDemoUser || isSystemUpdating}
        />
      </div>
    </div>
  );
};

NotificationToggle.propTypes = {
  isChecked: PropTypes.bool,
  onChangeToggle: PropTypes.func,
  isSystemUpdating: PropTypes.bool,
  isDemoUser: PropTypes.bool,
};

class SystemList extends Component {
  static propTypes = {
    systems: PropTypes.array.isRequired,
    updateUserProfile: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    userProfile: PropTypes.object.isRequired,
    activeTab: PropTypes.string.isRequired,
    updateSystemForEmailNotifications: PropTypes.func.isRequired,
    updateSystemForPushNotifications: PropTypes.func.isRequired,
    notificationSettingsTab: PropTypes.object.isRequired,
    isDemoUser: PropTypes.bool,
  };

  state = {
    systems: [],
    disableAllNotifications: false,
  };

  componentDidMount() {
    if (this.props.systems) {
      this.setState({
        systems: this.props.systems,
      });
    }
    if (this.props.userProfile.userId) {
      this.setState({
        disableAllNotifications:
          this.props.activeTab === email
            ? !this.props.userProfile.emailNotifications
            : !this.props.userProfile.pushNotifications,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeTab !== this.props.activeTab || prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        disableAllNotifications:
          this.props.activeTab === email
            ? !this.props.userProfile.emailNotifications
            : !this.props.userProfile.pushNotifications,
      });
    }
  }

  onNotifyByEmailChange = async (system, isChecked) => {
    const systemList = this.state.systems.map(sys => {
      if (sys === system) {
        return { ...sys, notifyByEmail: isChecked };
      }
      return sys;
    });
    await this.props.updateSystemForEmailNotifications(system.id, isChecked, systemList);
    this.setState({
      systems: systemList,
    });
  };

  onNotifyByPushChange = async (system, isChecked) => {
    const systemList = this.state.systems.map(sys => {
      if (sys === system) {
        return { ...sys, notifyByPush: isChecked };
      }
      return sys;
    });
    await this.props.updateSystemForPushNotifications(system.id, isChecked, systemList);
    this.setState({
      systems: systemList,
    });
  };

  onChangeToggle = async () => {
    const { userProfile } = this.props;
    if (this.props.activeTab === email) {
      await this.props.updateUserProfile(this.props.userId, {
        ...userProfile,
        emailNotifications: this.state.disableAllNotifications,
      });
    } else {
      await this.props.updateUserProfile(this.props.userId, {
        ...userProfile,
        pushNotifications: this.state.disableAllNotifications,
      });
    }
  };

  render() {
    const { systems, userProfile, activeTab, isDemoUser } = this.props;
    const { isSystemUpdating } = this.props.notificationSettingsTab;
    return (
      <div className="systemList">
        {!this.state.systems && !systems && !userProfile ? (
          <Spinner dark />
        ) : (
          <>
            <div className="systemList-item systemList-item--header">
              {systemCells.map(systemCell => (
                <SystemHeaderCell
                  key={systemCell.id}
                  id={systemCell.id}
                  defaultMessage={systemCell.defaultMessage}
                  lastCell={systemCell.lastCell}
                />
              ))}
            </div>
            <NotificationToggle
              activeTab={activeTab}
              isChecked={this.state.disableAllNotifications}
              isSystemUpdating={isSystemUpdating}
              userProfile={userProfile}
              onChangeToggle={this.onChangeToggle}
              isDemoUser={isDemoUser}
            />
            <div className={this.state.disableAllNotifications ? 'systemList--disable' : ''}>
              {this.state.systems.map((system, index) => (
                <SystemRow
                  disabled={isDemoUser || this.state.disableAllNotifications || isSystemUpdating}
                  key={index}
                  system={system}
                  activeTab={activeTab}
                  onNotifyByEmailChange={this.onNotifyByEmailChange}
                  onNotifyByPushChange={this.onNotifyByPushChange}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect(
  ({ app: { systems, userInfo }, profileTab: { userProfile }, notificationSettingsTab }) => ({
    systems,
    userProfile,
    notificationSettingsTab,
    activeTab: notificationSettingsTab.activeTab,
    isDemoUser: userInfo.isDemo,
  }),
  {
    updateSystemForEmailNotifications,
    updateSystemForPushNotifications,
    onChangeActiveTab,
    updateUserProfile,
  },
)(SystemList);
