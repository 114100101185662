import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as yup from 'yup';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import AccountNigrationModalSpinner from './components/AccountNigrationModalSpinner';

import { formatErrorMessage } from '../../../../../../localization/message-formatting';
import AccountMigrationModal from '../AccountMigrationModal';
import Spacer from '../../../../../layout/Spacer';
import { Link } from 'react-bootstrap/lib/Navbar';

const schemaStepOne = yup.object().shape({
  password: yup.string().required('password.required'),
});

const errorMessages = {
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  'invalid request': {
    id: 'password.error.request.invalid',
    defaultMessage: 'Password is invalid. Please try again.',
  },
  unknown: {
    id: 'migration.error.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
};

const AccountMigrationModalForm = ({
  intl,
  onSubmit,
  isOpen,
  onClose,
  isLoading,
  translationObject,
  handleForgotPasswordClick,
}) => {
  if (!isOpen) return null;
  return (
    <AccountMigrationModal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{ password: '' }}
        validationSchema={schemaStepOne}
        onSubmit={onSubmit}
        enableReinitialized={true}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <h2 className="account-migration-modal-form-description">
              <FormattedMessage
                id={`accountMigration${translationObject.key}.form.description`}
                defaultMessage={`Enter your password from ${translationObject.value}.`}
              />
            </h2>
            <div className="inputComponent">
              <FormattedMessage id="register.input-password" defaultMessage="Password">
                {placeholder => (
                  <Input
                    placeholder={placeholder}
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={
                      errors.password && touched.password && formatErrorMessage(intl, errorMessages, errors.password)
                    }
                  />
                )}
              </FormattedMessage>
            </div>
            <Spacer y={24} />
            <div className="account-migration-modal-link-wrapper">
              <Link onClick={handleForgotPasswordClick}>
                <FormattedMessage
                  id="accountMigrationForgotPasswordModal.forgotPasswordLink"
                  defaultMessage="Forgot Password?"
                />
              </Link>
            </div>
            <Spacer y={24} />
            <div className="account-migration-modal-footer">
              <Button
                id="accountMigrationModal.form.transfer"
                className="button--secondary"
                type="submit"
                disabled={isSubmitting || isLoading}
              >
                <div className="account-migration-modal-button-text">
                  <FormattedMessage id="accountMigrationModal.form.transfer" defaultMessage="Transfer" />
                  <AccountNigrationModalSpinner isLoading={isLoading} />
                </div>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </AccountMigrationModal>
  );
};

AccountMigrationModalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  translationObject: PropTypes.object,
  handleForgotPasswordClick: PropTypes.func,
};

export default injectIntl(AccountMigrationModalForm);
