import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button, Spinner, Title } from 'jpi-cloud-web-ui-components';

import ItemDate from '../ProductRegistration/components/ItemDate';

import { Warranty } from './Warranty';

import { getRegisteredProducts } from '../ProductRegistration/actions';

import './product-registration-info.scss';

const ProductRegistrationDetails = ({ productInfo, goToPage, locale }) => (
  <div className="row">
    <div className="col-lg-6">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>
              <FormattedMessage id="productRegistration.serial-number" defaultMessage="Serial Number" />
            </td>
            <td>{productInfo.serialNumber}</td>
          </tr>

          <tr>
            <td>
              <FormattedMessage id="productRegistration.installation-date" defaultMessage="Installation Date" />
            </td>
            <td>
              <ItemDate timestamp={productInfo.installationDate} locale={locale} />
            </td>
          </tr>

          <tr>
            <td>
              <FormattedMessage id="productRegistration.warranty-end-date" defaultMessage="Warranty End Date" />
            </td>
            <td>
              <ItemDate timestamp={productInfo.warrantyEndDate} locale={locale} />
            </td>
          </tr>

          <tr>
            <td>
              <FormattedMessage id="productRegistration.installerName" defaultMessage="Installer name" />
            </td>
            <td>{productInfo.installerName}</td>
          </tr>

          <tr>
            <td>
              <FormattedMessage id="productRegistration.more-information" defaultMessage="More information" />
            </td>
            <td>
              <a href="https://www.nibe.eu/de-de/">NIBE Systemtechnik GmbH</a>
            </td>
          </tr>
        </tbody>
      </table>
      <Button className="button--primary" onClick={() => goToPage('/product-registration')}>
        <FormattedMessage id="button.back" defaultMessage="Back" />
      </Button>
    </div>
  </div>
);
ProductRegistrationDetails.propTypes = {
  productInfo: PropTypes.object.isRequired,
  goToPage: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

class ProductRegistrationInfo extends React.Component {
  static propTypes = {
    productList: PropTypes.array.isRequired,
    userInfo: PropTypes.object,
    language: PropTypes.object.isRequired,
    history: PropTypes.object,
    match: PropTypes.object.isRequired,
    getRegisteredProducts: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.productList.length === 0) {
      this.props.getRegisteredProducts(this.props.userInfo.id);
    }
  }

  render() {
    const productInfo = this.props.productList.find(p => p.serialNumber === this.props.match.params.sn);

    return (
      <div className="product-registration-info">
        <div className="product-registration-info__wrapper">
          <Title titleTranslationId="productRegistration.subtitle" defaultMessage="Register Your Product" />

          {!productInfo && <Spinner />}

          {productInfo && (
            <>
              <ProductRegistrationDetails
                productInfo={productInfo}
                goToPage={this.props.history.push}
                locale={this.props.language.selectedLanguage}
              />
              <Warranty />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  app: { userInfo },
  productRegistration: {
    productList: { data: productList },
  },
  language,
}) => ({
  userInfo,
  productList,
  language,
});
const mapActionsToProps = {
  getRegisteredProducts,
};

export default connect(mapStateToProps, mapActionsToProps)(ProductRegistrationInfo);
