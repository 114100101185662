import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import * as yup from 'yup';

import { Input } from 'jpi-cloud-web-ui-components';

import { formatErrorMessage } from '../../../../../localization/message-formatting';
import { DemoAccountVerifier } from '../../../../layout/DemoAccountVerifier';

export const emailSchema = yup.object().shape({
  newEmail: yup
    .string()
    .email('new-email.email')
    .required('new-email.required')
    .trim()
    .test('oldEmail', 'new-email.duplicate', (newEmail, context) => {
      const { oldEmail } = context.parent;

      return newEmail !== oldEmail;
    }),
});

export const errorMessages = {
  'new-email.email': {
    id: 'profile.email.error.validation.new-email.email',
    defaultMessage: 'New E-Mail is not valid',
  },
  'new-email.required': {
    id: 'profile.email.error.validation.new-email.required',
    defaultMessage: 'New E-Mail is mandatory field',
  },
  'new-email.duplicate': {
    id: 'profile.email.error.validation.new-email.duplicate',
    defaultMessage: 'New E-Mail is same as current',
  },
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  password_wrong: {
    id: 'profile.email.error.request.password.invalid',
    defaultMessage: 'Supplied password is not correct',
  },
  email_already_taken: {
    id: 'profile.email.error.request.email.taken',
    defaultMessage: 'The E-Mail address is already in use. Please try another E-Mail address',
  },
};

const EditEmailForm = props => {
  return (
    <>
      <div className="address-row email-form">
        <FormattedMessage id="label.currentEmail" defaultMessage="Current Email">
          {placeholder => (
            <DemoAccountVerifier position="top">
              <Input
                disabled
                placeholder={placeholder}
                name="currentEmail"
                type="text"
                value={props.values.oldEmail}
                defaultValue={props.values.oldEmail}
              />
            </DemoAccountVerifier>
          )}
        </FormattedMessage>
        <DemoAccountVerifier position="top">
          <Input
            placeholder={
              props.isPendingNewEmail
                ? props.intl.formatMessage({ id: 'label.pendingNewEmail' })
                : props.intl.formatMessage({ id: 'label.newEmail' })
            }
            type="text"
            name="newEmail"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.newEmail}
            disabled={props.isDisabled}
            error={
              props.errors.newEmail &&
              props.touched.newEmail &&
              formatErrorMessage(props.intl, errorMessages, props.errors.newEmail)
            }
          />
        </DemoAccountVerifier>
      </div>
    </>
  );
};

EditEmailForm.propTypes = {
  intl: PropTypes.object,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isPendingNewEmail: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

export default injectIntl(EditEmailForm);
