import { useMutation } from '@tanstack/react-query';
import { verifyOtp } from '../../../../../../api/accountMigration';

export function useVerifyOtp(email, onMigrateUserWithOtp) {
  return useMutation(otp => verifyOtp(email, otp), {
    onSuccess: response => {
      const oldUserId = response?.data;
      onMigrateUserWithOtp(oldUserId);
    },
  });
}
