import * as yup from 'yup';
import moment from 'moment';

import {
  address,
  phone,
  defaultMaxLength,
  yourTechnicianPattern,
  userNameRegex,
} from '../../../../constants/constants';

export const registrationFormSchema = yup.object().shape({
  serialNumber: yup.string().trim().required('serial-number.required'),
  productName: yup
    .string()
    .trim()
    .max(defaultMaxLength, 'product-name.maxlength')
    .matches(userNameRegex, 'product-name.specialChars')
    .required('product-name.required'),
  name: yup.string().trim().max(defaultMaxLength, 'name.maxlength').required('name.required'),
  email: yup.string().email('email.email').trim().required('email.required'),
  phone: yup
    .string()
    .trim()
    .min(phone.minLength, 'phone.minlength')
    .max(phone.maxLength, 'phone.maxlength')
    .matches(phone.regex, 'phone.regexp')
    .required('phone.required'),
  operatingHours: yup
    .string()
    .min(0, 'operating-hours.minvalue')
    .max(1200, 'operating-hours.maxvalue')
    .test('isInteger', 'operating-hours.integer', opHours => /^[0-9]+$/i.test(opHours))
    .test('installationDate', 'operating-hours.overclock', (opHours = 0, ctx) => {
      const hoursFromInstall = moment(ctx.resolve(yup.ref('installationDate'))).add(opHours, 'h');
      return !moment(hoursFromInstall).isAfter(moment());
    })
    .required('operating-hours.required'),
  installationDate: yup
    .string()
    .required('installation-date')
    .trim()
    .default(moment(new Date()).toString())
    .test('maxDate', 'installation-date.maxdate', function () {
      const dateRaw = this.resolve(yup.ref('installationDate'));
      return moment(dateRaw).isBefore(moment());
    })
    .test('minDate', 'installation-date.mindate', function () {
      const dateRaw = this.resolve(yup.ref('installationDate'));
      const fiveYearsAgo = moment().add(-5, 'years');
      return moment(dateRaw).isAfter(fiveYearsAgo);
    })
    .test('dateFormat', 'installation-date.date-format', function () {
      const dateRaw = this.resolve(yup.ref('installationDate'));
      return moment(dateRaw, 'YYYY-MM-DD', true).isValid();
    }),
  installerName: yup
    .string()
    .trim()
    .max(defaultMaxLength, 'installer-name.maxlength')
    .min(3, 'installer-name.search.minlength')
    .required('installer-name.required')
    .matches(yourTechnicianPattern.name.pattern, 'installer-name.invalid-format'),
  address1: yup
    .string()
    .trim()
    .max(defaultMaxLength, 'address.line-one.maxlength')
    .required('address.line-one.required')
    .matches(address.pattern, 'address.line-one.invalid-format'),
  address2: yup
    .string()
    .trim()
    .max(defaultMaxLength, 'address.line-two.maxlength')
    .matches(address.pattern, 'address.line-two.invalid-format')
    .nullable(),
  postalCode: yup
    .string()
    .trim()
    .max(address.postalCode.maxLength, 'postal-code.length')
    .required('postal-code.required')
    .matches(address.postalCode.regex, 'postal-code.regexp'),
  city: yup
    .string()
    .trim()
    .max(address.cityNameMaxLength, 'city.maxlength')
    .matches(address.pattern, 'city.invalid-format')
    .required('city.required'),
  region: yup
    .string()
    .trim()
    .max(address.regionNameMaxLength, 'region.maxlength')
    .matches(address.pattern, 'region.invalid-format')
    .nullable(),
  country: yup.object().nullable().required('country.required'),
  warrantyConditions: yup
    .boolean()
    .required('warranty-conditions.required')
    .oneOf([true], 'warranty-conditions.required'),
  dataConsent: yup.boolean().required('data-consent.required').oneOf([true], 'data-consent.required'),
});

export const errorMessages = {
  'serial-number.maxlength': {
    id: 'serial-number.error.validation.maxlength',
    defaultMessage: 'Serial number cannot be longer than 20 characters',
  },
  'serial-number.required': {
    id: 'serial-number.error.validation.required',
    defaultMessage: 'Serial number is required',
  },
  'product-name.maxlength': {
    id: 'product-registration.error.validation.product-name.maxlength',
    defaultMessage: 'Product name cannot be longer than 255 characters',
  },
  'product-name.specialChars': {
    id: 'product-registration.error.validation.product-name.specialChars',
    defaultMessage: 'Product name can not contain special characters',
  },

  'product-name.required': {
    id: 'product-registration.error.validation.product-name.required',
    defaultMessage: 'Product name is required',
  },
  'name.maxlength': {
    id: 'product-registration.error.validation.name.maxlength',
    defaultMessage: 'Name cannot be longer than 255 characters',
  },
  'name.required': {
    id: 'product-registration.error.validation.name.required',
    defaultMessage: 'Name is required',
  },
  'email.email': {
    id: 'email.error.validation.email',
    defaultMessage: 'E-Mail is not valid',
  },
  'email.required': {
    id: 'email.error.validation.required',
    defaultMessage: 'E-Mail is required',
  },
  'phone.minlength': {
    id: 'phone-number.error.validation.minlength',
    defaultMessage: 'Phone number cannot be shorter than 5 characters',
  },
  'phone.maxlength': {
    id: 'phone-number.error.validation.maxlength',
    defaultMessage: 'Phone number cannot be longer than 25 characters',
  },
  'phone.regexp': {
    id: 'phone-number.error.validation.regexp',
    defaultMessage: 'Phone number is not valid',
  },
  'phone.required': {
    id: 'phone-number.error.validation.required',
    defaultMessage: 'Phone number is required',
  },
  'operating-hours.minvalue': {
    id: 'product-registration.error.validation.operating-hours.minvalue',
    defaultMessage: 'Operating hours value cannot be less than 0',
  },
  'operating-hours.maxvalue': {
    id: 'product-registration.error.validation.operating-hours.maxvalue',
    defaultMessage: 'Operating hours value cannot be more than 1200',
  },
  'operating-hours.integer': {
    id: 'product-registration.error.validation.operating-hours.integer',
    defaultMessage: 'Operating hours value has to be non-decimal number',
  },
  'operating-hours.overclock': {
    id: 'product-registration.error.validation.operating-hours.overclock',
    defaultMessage: 'Operating hours and installation date do not match',
  },
  'operating-hours.required': {
    id: 'product-registration.error.validation.operating-hours.required',
    defaultMessage: 'Operating hours value is required',
  },
  'installation-date.required': {
    id: 'product-registration.error.validation.installation-date.required',
    defaultMessage: 'Installation date is required',
  },
  'installation-date.maxdate': {
    id: 'product-registration.error.validation.installation-date.maxdate',
    defaultMessage: 'Installation date cannot be later than today',
  },
  'installation-date.mindate': {
    id: 'product-registration.error.validation.installation-date.mindate',
    defaultMessage: 'Installation date cannot be earlier than 5 years ago',
  },
  'installation-date.date-format': {
    id: 'product-registration.error.validation.installation-date.date-format',
    defaultMessage: 'Installation date has invalid format',
  },
  'installer-name.maxlength': {
    id: 'product-registration.error.validation.installer-name.maxlength',
    defaultMessage: 'Installer name cannot be longer than 255 characters',
  },
  'installer-name.search.minlength': {
    id: 'product-registration.error.validation.installer-name.search.minlength',
    defaultMessage: 'Installer name must have more than 2 characters',
  },
  'installer-name.required': {
    id: 'product-registration.error.validation.installer-name.required',
    defaultMessage: 'Installer name is required',
  },
  'installer-name.invalid-format': {
    id: 'service-partner.error.validation.name.invalid-format',
    defaultMessage: 'Installer name has invalid format',
  },
  'address.line-one.maxlength': {
    id: 'address.error.validation.line-one.maxlength',
    defaultMessage: 'Address line 1 cannot be longer than 255 characters',
  },
  'address.line-one.required': {
    id: 'address.error.validation.line-one.required',
    defaultMessage: 'Address line 1 is required',
  },
  'address.line-one.invalid-format': {
    id: 'address.error.validation.line-one.invalid-format',
    defaultMessage: 'Address line 1 has invalid format',
  },
  'address.line-two.maxlength': {
    id: 'address.error.validation.line-two.maxlength',
    defaultMessage: 'Address line 2 cannot be longer than 255 characters',
  },
  'address.line-two.invalid-format': {
    id: 'address.error.validation.line-two.invalid-format',
    defaultMessage: 'Address line 2 has invalid format',
  },
  'postal-code.maxlength': {
    id: 'postal-code.error.validation.maxlength',
    defaultMessage: 'Postal code cannot be longer than 20 characters',
  },
  'postal-code.required': {
    id: 'postal-code.error.validation.required',
    defaultMessage: 'Postal code is mandatory field',
  },
  'postal-code.regexp': {
    id: 'postal-code.error.validation.regexp',
    defaultMessage: 'Postal code has an invalid format',
  },
  'city.required': {
    id: 'city.error.validation.required',
    defaultMessage: 'City is mandatory field',
  },
  'city.maxlength': {
    id: 'city.error.validation.maxlength',
    defaultMessage: 'City cannot be longer than 100 characters',
  },
  'city.invalid-format': {
    id: 'city.error.validation.invalid-format',
    defaultMessage: 'City has invalid format',
  },
  'region.maxlength': {
    id: 'region.error.validation.maxlength',
    defaultMessage: 'Region cannot be longer than 100 characters',
  },
  'region.invalid-format': {
    id: 'region.error.validation.invalid-format',
    defaultMessage: 'Region has invalid format',
  },
  'country.required': {
    id: 'country.error.validation.required',
    defaultMessage: 'City is mandatory field',
  },
  'warranty-conditions.required': {
    id: 'product-registration.error.validation.warranty-conditions.required',
    defaultMessage: 'Must accept Warranty Conditions',
  },
  'data-consent.required': {
    id: 'product-registration.error.validation.data-consent.required',
    defaultMessage: 'Must accept Data Consent',
  },
};
