import * as api from '../../../api';
import { logoutUser } from '../Login/actions';
import * as azureB2CApi from '../../../api/azureB2C';
import { putAuthTokens, setAzureB2CPolicyName } from '../../../localStorage/authentication';
export const CHANGE_PASSWORD_REQUEST = 'profile/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_RESPONSE_SUCCEDED = 'profile/CHANGE_PASSWORD_RESPONSE_SUCCEDED';
export const CHANGE_PASSWORD_FAILED = 'profile/CHANGE_PASSWORD_FAILED';

export const changePassword = (id, oldPassword, newPassword) => async dispatch => {
  dispatch({ type: CHANGE_PASSWORD_REQUEST });
  try {
    const response = await api.changePassword(id, oldPassword, newPassword);
    if (response.status === 204) {
      dispatch({ type: CHANGE_PASSWORD_RESPONSE_SUCCEDED });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      dispatch({ type: CHANGE_PASSWORD_FAILED, error: 'old-password.invalid' });
      return false;
    }
  }

  dispatch({ type: CHANGE_PASSWORD_FAILED, error: 'unknown' });
  return false;
};

export const ISSUE_TOKEN_PASSWORD_CHANGE_REQUEST = 'profile/ISSUE_TOKEN_PASSWORD_CHANGE_REQUEST';
export const ISSUE_TOKEN_PASSWORD_CHANGE_RESPONSE = 'profile/ISSUE_TOKEN_PASSWORD_CHANGE_RESPONSE';
export const ISSUE_TOKEN_PASSWORD_CHANGE_FAILED = 'profile/ISSUE_TOKEN_PASSWORD_CHANGE_FAILED';

export const issueAzureB2CTokensAfterPasswordChangeAction = code => async dispatch => {
  dispatch({ type: ISSUE_TOKEN_PASSWORD_CHANGE_REQUEST });

  try {
    const response = await azureB2CApi.issueAzureB2CTokens(code, azureB2CApi.AZURE_B2C_POLICY_PASSWORD_CHANGE);

    if (response.status === 200) {
      putAuthTokens(response.data);
      setAzureB2CPolicyName(azureB2CApi.AZURE_B2C_POLICY_PASSWORD_CHANGE);
      dispatch({ type: ISSUE_TOKEN_PASSWORD_CHANGE_RESPONSE });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 400 && e.response.data.error === 'invalid_grant') {
      dispatch({ type: ISSUE_TOKEN_PASSWORD_CHANGE_FAILED, error: e.response.data.description });
      return false;
    }
  }

  dispatch({ type: ISSUE_TOKEN_PASSWORD_CHANGE_FAILED, error: 'unknown' });
  return false;
};

export const CHANGE_EMAIL_REQUEST = 'profile/CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_RESPONSE_SUCCEDED = 'profile/CHANGE_EMAIL_RESPONSE_SUCCEDED';
export const CHANGE_EMAIL_FAILED = 'profile/CHANGE_EMAIL_FAILED';

export const changeEmail = (id, oldEmail, newEmail, password) => async dispatch => {
  dispatch({ type: CHANGE_EMAIL_REQUEST });
  try {
    const response = await api.changeEmail(id, oldEmail, newEmail, password);
    if (response.status === 202) {
      dispatch({ type: CHANGE_EMAIL_RESPONSE_SUCCEDED, email: newEmail });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 409) {
      dispatch({ type: CHANGE_EMAIL_FAILED, error: 'email_already_taken' });
      return { error: 'email_already_taken' };
    }
    if (e.response && e.response.status === 400) {
      dispatch({ type: CHANGE_EMAIL_FAILED, error: 'password_wrong' });
      return { error: 'password_wrong' };
    }
  }

  dispatch({ type: CHANGE_EMAIL_FAILED, error: 'unknown' });
  return false;
};

export const DELETE_ACCOUNT_REQUEST = 'profile/DELETE_ACCOUNT';
export const DELETE_ACCOUNT_RESPONSE_SUCCEDED = 'profile/DELETE_ACCOUNT_RESPONSE_SUCCEDED';
export const DELETE_ACCOUNT_FAILED = 'profile/DELETE_ACCOUNT_FAILED';

export const deleteAccount = (userId, password) => async dispatch => {
  dispatch({ type: DELETE_ACCOUNT_REQUEST });
  try {
    await api.deleteUserAccount(userId, password);
    dispatch({ type: DELETE_ACCOUNT_RESPONSE_SUCCEDED });
    dispatch(logoutUser(false));
    return true;
  } catch (e) {
    if (e.response && e.response.status === 400) {
      dispatch({ type: DELETE_ACCOUNT_FAILED, error: 'last.admin' });
    } else {
      dispatch({ type: DELETE_ACCOUNT_FAILED, error: 'password' });
    }
    return false;
  }
};

export const VALIDATE_CREDENTIALS_REQUEST = 'profile/VALIDATE_CREDENTIALS_REQUEST';
export const VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED = 'profile/VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED';
export const VALIDATE_CREDENTIALS_RESPONSE_FAILED = 'profile/VALIDATE_CREDENTIALS_RESPONSE_FAILED';
export const validateUserCredentials = (username, password) => async dispatch => {
  dispatch({ type: VALIDATE_CREDENTIALS_REQUEST });
  try {
    const response = await api.validateUserCredentials(username, password);
    if (response.status === 200) {
      dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      // User is already logged in, so we don't expect errors like 'User does not exist', or 'Email is not confirmed'
      dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_FAILED, error: 'password.incorrect' });
      return false;
    }
  }

  dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_FAILED, error: 'unknown' });
  return false;
};

export const RESET_DELETE_ERROR = 'profile/RESET_DELETE_ERROR ';
export const resetDeleteError = () => dispatch => dispatch({ type: RESET_DELETE_ERROR });

export const RESET_EMAIL_FORM_ERROR_REQUEST = 'profile/RESET_EMAIL_FORM_ERROR_REQUEST';
export const resetEmailError = () => dispatch => dispatch({ type: RESET_EMAIL_FORM_ERROR_REQUEST });
